import React, { Suspense, useEffect } from "react";
import { hideSessionExpiredModal } from "./utills/SessionExpiredModal";
import { hideLoader } from "./utills/loaderUtils";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import LoginPage from "./Components/Profile/Login/LoginPage";
import ForgotPassword from "./Components/Profile/Login/ForgotPassword/ForgotPassword";
import ResetPassword from "./Components/Profile/Login/ResetPassword/ResetPassword";
import SignupPage from "./Components/Profile/Signup/SignupPage";
import ServiceType from "./Components/Profile/ServiceType/ServiceType";
import ConfirmRegister from "./Components/Profile/ConfirmRegister/ConfirmRegister";
import ProfileInfo from "./Components/Profile/ProfileInfo/ProfileInfo";
import MainDashBoard from "./Components/Dashboard/MainDashBoard/MainDashBoard";
import DashboardAppointments from "./Components/Dashboard/DashboardAppointments/DashboardAppointments";
import DashboardBookings from "./Components/Dashboard/DashboardBookings/DashboardBookings";
import DashboardPatientList from "./Components/Dashboard/DashboardPatientList/DashboardPatientList";
import DashboardPayments from "./Components/Dashboard/DashboardPayments/DashboardPayments";
import DashboardSettings from "./Components/Dashboard/DashboardSettings/DashboardSettings";
import Accountinfo from "./Components/Dashboard/DashboardSettings/Accountinfo/Accountinfo";
import Security from "./Components/Dashboard/DashboardSettings/Security/Security";
import Preferences from "./Components/Dashboard/DashboardSettings/Preferences/Preferences";
import Notifications from "./Components/Dashboard/DashboardSettings/Notifications/Notifications";
import Privacy from "./Components/Dashboard/DashboardSettings/Privacy/Privacy";
import Payouts from "./Components/Dashboard/DashboardSettings/Payouts/Payouts";
import DashboardHelpSupport from "./Components/Dashboard/DashboardHelpSupport/DashboardHelpSupport";
import DashboardReports from "./Components/Dashboard/DashboardReports/DashboardReports";
import { PrivateRoute, PublicRoute } from "./Common/Routes/Routes";
import NotFound from "./Common/NotFound/NotFound";
import "./App.scss";
import clearLocalStorage from "./Common/setLocalStorage/clearLocalStorage";
import Reviews from "./Components/Dashboard/DashboardSettings/Reviews/Reviews";

const App = () => {
  useEffect(() => {
    hideLoader();
    hideSessionExpiredModal();
  }, []);
  const handleLoginClick = () => {
    hideSessionExpiredModal();
    clearLocalStorage()
    window.location.href = "/";
  };
  return (
    <>
      <div className="loaderSpinner">
        <div id="loader" className="loader-container">
          <div className="loader" />
        </div>
      </div>
      <div id="SessionExpiredModal">
        <div className="session-expired-modal">
          <div className="modal_content_block">
            <h2>Session Expired</h2>
            <p>Your session has expired. Please log in again.</p>
            <button className="login-button" onClick={handleLoginClick}>
              Log In
            </button>
          </div>
        </div>
      </div>
      <div>
        <Router>
          <Switch>
            <PublicRoute exact path="/" component={LoginPage} />
            <PublicRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            <PublicRoute
              exact
              path="/reset-password"
              component={ResetPassword}
            />
            <PublicRoute exact path="/sign-up" component={SignupPage} />
            <PublicRoute exact path="/servicetype" component={ServiceType} />
            <PublicRoute
              exact
              path="/confirmregister"
              component={ConfirmRegister}
            />
            <PublicRoute exact path="/profileinfo" component={ProfileInfo} />
            <PrivateRoute
              exact
              path="/maindashboard"
              component={MainDashBoard}
            />
            <PrivateRoute
              exact
              path="/dashboardappointments"
              component={DashboardAppointments}
            />
            <PrivateRoute
              exact
              path="/dashboardschedules"
              component={DashboardBookings}
            />
            <PrivateRoute
              exact
              path="/dashboardpatientlist"
              component={DashboardPatientList}
            />
            <PrivateRoute
              exact
              path="/dashboardpayments"
              component={DashboardPayments}
            />
            <PrivateRoute
              exact
              path="/dashboardsettings"
              component={DashboardSettings}
            />
            <PrivateRoute
              exact
              path="/dashboardsettings/accountinfo"
              component={Accountinfo}
            />
            <PrivateRoute
              exact
              path="/dashboardsettings/reviews"
              component={Reviews}
            />
            <PrivateRoute
              exact
              path="/dashboardsettings/security"
              component={Security}
            />
            <PrivateRoute
              exact
              path="/dashboardsettings/preferences"
              component={Preferences}
            />
            <PrivateRoute
              exact
              path="/dashboardsettings/notifications"
              component={Notifications}
            />
            <PrivateRoute
              exact
              path="/dashboardsettings/privacy"
              component={Privacy}
            />
            <PrivateRoute
              exact
              path="/dashboardsettings/payouts"
              component={Payouts}
            />
            <PrivateRoute
              exact
              path="/dashboard-reports"
              component={DashboardReports}
            />
            <PrivateRoute
              exact
              path="/help-support"
              component={DashboardHelpSupport}
            />
            <Route exact path="/404" component={NotFound} />
            <Redirect from="*" to="/404" />
          </Switch>
        </Router>
      </div>
    </>
  );
};

export default App;
