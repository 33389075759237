import React, { useState,useEffect } from "react";
import DashboardLayout from "../DashboardLayout";
import { Icon, Badge } from "antd";
import { useHistory } from "react-router-dom";
import "./DashboardReports.scss";
import Reports from "./Reports/Reports";
import Reviews from "./Reviews/Reviews";
import Axiosinstance from "../../../config";
import GetlocalStorage from "../../../Common/setLocalStorage/GetlocalStorage";

const DashboardReports=()=> {
  const history = useHistory();
  
  let getlocalStorage = GetlocalStorage();
  const fullName = getlocalStorage.fullName;
  const [ReportType, setReportType] = useState("Reports");
  const [updateReview, setupdateReview] = useState(false)
  const [ReviewList, setReviewList] = useState([]);


  let CareGiverid = getlocalStorage.CareGiverid;
console.log("CareGiverid",CareGiverid)

  useEffect(() => {
    handleFetchReviews();
  }, [updateReview]);

  const handleFetchReviews = async () => {
    try {
      const res = await Axiosinstance.get(
        `client/doc/v1.0/getReviews/all/${CareGiverid}`
      );
      if (res.status === 200) {
        setReviewList(res.data.data);
      }
    } catch (error) {}
  };


  return (
    <DashboardLayout>
      <div className="DashboardReports-Body">
        <h3
          onClick={() => setReportType("Reports")}
          style={{  cursor: "pointer" }}
        >
          Reports {ReportType === "Reviews" && "< Reviews"}
        </h3>
        <div className="DashboardReports-ProfileInfo">
          <Icon
            style={{ visibility: "hidden" }}
            type="caret-down"
            theme="filled"
          />
          <h3 onClick={() => history.push("/dashboardsettings/accountinfo")}>
            {fullName}
          </h3>
          <Badge offset={[-10, 8]} color={"#2d80b5"} dot>
            <Icon
              onClick={() => history.push("/dashboardsettings/notifications")}
              type="bell"
              theme="filled"
            />
          </Badge>
        </div>
        {ReportType === "Reports" && <Reports setReportType={setReportType} ReviewList={ReviewList} setupdateReview={setupdateReview} />}
        {ReportType === "Reviews" && <Reviews ReviewList={ReviewList} setupdateReview={setupdateReview} />}
      </div>
    </DashboardLayout>
  );
}

export default DashboardReports;
