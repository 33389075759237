import React, { useEffect } from "react";
import { Pie } from "@ant-design/charts";

const PieChart = ({ doctors, providers, users }) => {
  let ref;
  var data = [
    {
        type: "Under Treatment",
        value: 42,
      },
    {
      type: "Cured",
      value: 58,
    },
  
 
  ];

  var config = {
    appendPadding: -20,
    height: 346,
    data: data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      autoHide: true,
      type: "inner",
      visible: false,
      offset: "0",
      content: "{name}",
      style: {
        fontSize: "1.6rem",
        textAlign: "center",
      },
    },
    legend: true,
    color: ["#b0779a", "#81837f"],
    state: {
      active: {
        style: {
          lineWidth: 0,
          fillOpacity: 0.65,
        },
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
  };

  useEffect(() => {
    if (ref) {
      ref.setState("active", (data) => data.type === "分类一");
      ref.setState(
        "selected",
        (data) =>
          data.type === "Cured" ||
          data.type === "Under Treatment");
    }
  }, [ref]);

  const SetstatusColor = (status) => {
    switch (status) {
      case "Cured":
        return "#2D80B5";
      case "Under Treatment":
        return "#b0779a";

      default:
        break;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "70%" }}>
        <Pie {...config} chartRef={(chartRef) => (ref = chartRef)} />
      </div>
      
    </div>
  );
};
export default PieChart;
