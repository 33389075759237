import Axiosinstance from "../../config";
import { Fetch_PatientList_Request } from "./PatientListActionTypes";
import { PatientList_bookings_Request } from "./PatientListActionTypes";



const Patientsapi="/patient/v1.0/getPatients"


const PatientList_fetch = (id) => Axiosinstance.get(`${Patientsapi}/${id}`);

export const PatientList_fetch_Action = (id,routercall,handleLoader) => async (dispatch) => {
  let res;
  console.log("responses", id)
  handleLoader(true)
  try {
    res = await PatientList_fetch(id);   
    if (res.status === 200) {
      dispatch(PatientList_fetch_Request(res.data));
      handleLoader(false)
    } else if(res.status === 401) {
      routercall("/")
      handleLoader(false)
    }
  } catch (error) {
    console.log("PatientList__fetch_Request-error", error);
  }
}



const Patientbooking_Api="/appointment/v1.0/getAppointmentsByPatients"


const PatientList_bookings = (id,patientId) => Axiosinstance.get(`${Patientbooking_Api}/${id}/${patientId}`,);

export const PatientList_bookings_Action = (id,patientId,routercall,handleLoader) => async (dispatch) => {
  let res;
  handleLoader(true)
  try {
    res = await PatientList_bookings(id,patientId);
      
    if (res.status === 200) {
      dispatch(PatientList_booking_Request(res.data));
      routercall("PatientInfo")
      handleLoader(false)
    } else if(res.status === 401) {
      routercall("/")
    }
    
    
  } catch (error) {
    console.log("PPatientList_bookings_Request-error", error);
  }
}
const PatientList_fetch_Request = (PatientListinfo) => ({
    type: Fetch_PatientList_Request,
    payload: PatientListinfo,
  });
  const PatientList_booking_Request = (Patientbookinginfo) => ({
    type: PatientList_bookings_Request,
    payload: Patientbookinginfo,
  });