import React, { useState, useEffect } from "react";
import { Row, Col, Checkbox, message } from "antd";
import { useHistory } from "react-router-dom";
import "./ServiceType.scss";
import header_logo from "../../../Assets/header_logo.png";
import Arrowbutton from "../../../Assets/Arrowbutton.png";
import Axiosinstance from "../../../config";
import { servicesIDSetter, servicesNameSetter } from "../../../Common/setLocalStorage/setLocalStorage";

const ServiceType = () => {
  const [servicesName, setservicesName] = useState([]);
  const [servicesID, setservicesID] = useState([]);
  const [Serviceslist, setServiceslist] = useState([]);
  const [servicesResponse, setservicesResponse] = useState();
  const history = useHistory();

  const handleChangeCheckbox = (checkedList) => {
    setServiceslist(checkedList);
  };

  useEffect(() => {
    const servicesNamefilter = Serviceslist.map((item) => item.serviceName);
    const servicesIdfilter = Serviceslist.map((item) => item._id);
    setservicesName(servicesNamefilter);
    setservicesID(servicesIdfilter);
  }, [Serviceslist]);

  console.log("servicesID", servicesID);
  console.log("servicesName", servicesName);
  const Getservices = async () => {
    try {
      const response = await Axiosinstance.get("auth/v1.0/services");
      setservicesResponse(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    Getservices();
  }, []);

  const handleSubmitform = () => {
    if (servicesName.length > 0) {
      history.push({
        pathname: "/sign-up",
        state: { servicesName, servicesID },
      });
      servicesNameSetter(JSON.stringify(servicesName));
      servicesIDSetter(JSON.stringify(servicesID)) 
    } else {
      message.error("please select service ");
    }
  };

  return (
    <div className="ServiceType-Body">
      <div className="ServiceType-Body-Headerlogo">
        <img src={header_logo} alt="Logo" />
      </div>
      <Row>
        <Col lg={14} sm={24}>
          <div className="ServiceType-Body-Heading">
            <h3>“We need to ask some questions to ensure we</h3>
            <h3>provide you the best care”</h3>
          </div>
          <div className="ServiceType-Body-img">
            <img
              src={require("../../../Assets/caregiver_illustration.svg")}
              alt="Logo"
            />
          </div>
        </Col>
        <Col lg={10} sm={24}>
          {/* <div className="ServiceType-Body-dot-setup">
            <div className="active-dot"></div>
            <div className="disable-dot"></div>
            <div className="disable-dot"></div>
            <div className="disable-dot"></div>
          </div> */}

          <div className="ServiceType-Body-Question-setup">
            <h1>Choose your service for patient ?</h1>
            <Checkbox.Group
              onChange={handleChangeCheckbox}
              value={Serviceslist}
            >
              {servicesResponse?.map((item, i) => {
                return <Checkbox value={item}>{item.serviceName}</Checkbox>;
              })}
            </Checkbox.Group>
          </div>
          <div className="ServiceType-Body-button">
            <img onClick={handleSubmitform} src={Arrowbutton} alt="Logo" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ServiceType;
