import React from 'react';
import { Line } from '@ant-design/charts';
const LineChart = () => {
    var data = [
  
        {
            Month: 'JAN',
            value: 1,
            name:"Caregiver"
        },
    
        {
            Month: 'FEB',
            value: 2,
            name:"Caregiver"
        },
  
        {
            Month: 'MAR',
            value: 3,
            name:"Caregiver"
        },
     
        {
            Month: 'APR',
            value: 10,
            name:"Caregiver"
        },

     
    ];
    var config = {
        data: data,
        xField: 'Month',
        yField: 'value',
        seriesField:'name',
        legend: { position: 'bottom',shape: 'circle',layout: 'horizontal',
        marker: {
            symbol: 'circle',
            lineWidth: 0.5,
          }
    },
  
    color: ["#b0779a", "#81837f"],
        label: {},
        point: {
            size: 5,
            shape: 'circle',
            style: {
                fill: 'white',
                stroke: '#5B8FF9',
                lineWidth: 0.5,
            },
        },
        tooltip: { showMarkers: false },
        state: {
            active: {
                style: {
                    shadowColor: 'yellow',
                    shadowBlur: 4,
                    stroke: 'transparent',
                    fill: 'red',
                },
            },
        },
        theme: {
            geometries: {
                point: {
                    diamond: {
                        active: {
                            style: {
                                shadowColor: '#FCEBB9',
                                shadowBlur: 2,
                                stroke: '#F6BD16',
                            },
                        },
                    },
                },
            },
        },
        interactions: [{ type: 'marker-active' }],
    };
    return <Line {...config}/>;
};
export default LineChart;