import moment from "moment";
import axios from "axios";

export const blockInvalidChar = e =>
["e", "E", "+", "-","#","$","(","@",")"].includes(e.key) && e.preventDefault();

export const  funturedisabledDate=(current)=> {
    
    return current && current.valueOf() > Date.now();
  }
  export const  PreviousdisabledDate=(current)=> {
    return current && current <= moment().add(-1, "days");
  }
  export const  disableDateRanges=(range = { startDate: false, endDate: false })=> {
    const { startDate, endDate } = range;
    return function disabledDate(current) {
      let startCheck = true;
      let endCheck = true;
      if (startDate) {
        startCheck = current && current <= moment().add(-1, "days");
      }
      if (endDate) {
        endCheck = current && current > moment(endDate, 'YYYY-MM-DD');
      }
      return (startDate && startCheck) || (endDate && endCheck);
    };
  }

  export const getGeoInfo = async () => {
    try {
      const res = await axios.get("https://ipapi.co/json/");
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };