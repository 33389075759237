import clearLocalStorage from "../../Common/setLocalStorage/clearLocalStorage";
import { setLoginDetails } from "../../Common/setLocalStorage/setLocalStorage";
import Axiosinstance from "../../config";
import {
  Doctor_Login_Forget,
  Doctor_Login_Request,
  Doctor_Login_reset,
  Doctor_Logout_Request,
} from "./LoginActionTypes";

const Login_api = "auth/v1.0/signin";

const Login_create = (body) => Axiosinstance.post(Login_api, body);

export const Doctor_Login_Action =
  (data, routercall, handleLoader, handlestatus) => async (dispatch) => {
    handleLoader(true);
    var res;
    try {
      res = await Login_create(data);
      handleLoader(false);
      console.log("res", res);
      if (res.status === 200) {
        let data = res.data.data;
        setLoginDetails(data);
        routercall(
          res.data.data.isAdminApproved,
          res.data.data.isProfileCreated,
          res.data.data.isProfileCompleted
        );
        handlestatus(
          res.status,
          res.data.message,
          res.data.data.isAdminApproved,
          res.data.data.isProfileCreated,
          res.data.data.isProfileCompleted
        );
      } else {
        handlestatus(
          res.status,
          res.data.message,
          res.data.data.isAdminApproved,
          res.data.data.isProfileCreated,
          res.data.data.isProfileCompleted
        );
      }
    } catch (error) {
      handleLoader(false);
      dispatch(Login_Request(error));
    }
  };

const Logout_Api = "auth/v1.0/logout";

const Logout_post = (id) => Axiosinstance.get(`${Logout_Api}/${id}`);

export const Doctor_Logout_Action = (id, routercall) => async (dispatch) => {
  try {
    const res = await Logout_post(id);
    console.log("res", res);
    if (res.data.status === 200) {
      window.location.reload(true);
      dispatch(Logout_Request(res.data));
      routercall(res.data.message);
      clearLocalStorage()
    } else if (res.status === 400) {
    }
  } catch (error) {
    console.log("Login_CreateApi-error", error);
  }
};

const Login_forgot_Api = "auth/v1.0/forgot";

const Login_forgot_create = (body) =>
  Axiosinstance.post(Login_forgot_Api, body);

export const Doctor_Login_forgot_Action =
  (data, routercall, handleLoader) => async (dispatch) => {
    const apidata = {
      email: data,
    };
    handleLoader(true);
    try {
      const res = await Login_forgot_create(apidata);
      console.log("res", res.data.data);
      if (res.data.status === 200) {
        dispatch(Login_forgot(res.data));
        routercall();
        handleLoader(false);
      } else if (res.status === 404) {
        console.log("resstatus", res.data.message);
        routercall(res.data.message);
        handleLoader(false);
      }
    } catch (error) {
      console.log("Login_CreateApi-error", error);
    }
  };

const Login_reset_Api = "auth/v1.0/restpassword/email";

const Login_rest_create = (body) => Axiosinstance.post(Login_reset_Api, body);

export const Doctor_Login_reset_Action =
  (data, routercall, handleLoader) => async (dispatch) => {
    handleLoader(true);
    try {
      const res = await Login_rest_create(data);
      console.log("res", res);
      if (res.data.status === 200) {
        dispatch(Login_reset(res.data));
        routercall();
        handleLoader(true);
      } else if (res.status === 400) {
        console.log("resstatus", res.data.message);
        routercall(res.data.errors[0].msg);
        handleLoader(false);
      }
    } catch (error) {
      console.log("Login_CreateApi-error", error);
    }
  };

const Logout_Request = (Doctorinfo) => ({
  type: Doctor_Logout_Request,
  payload: Doctorinfo,
});

const Login_reset = (Doctorinfo) => ({
  type: Doctor_Login_reset,
  payload: Doctorinfo,
});

const Login_forgot = (Doctorinfo) => ({
  type: Doctor_Login_Request,
  payload: Doctorinfo,
});

const Login_Request = (Doctorinfo) => ({
  type: Doctor_Login_Forget,
  payload: Doctorinfo,
});
