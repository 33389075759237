
import { Fetch_Appointments_Request, Fetch_Appointments_Count_Request, Fetch_Consultation_Request, Fetch_Todays_Appointments_Request } from "./AppointmentsActionTypes";

export const ConsultationsReducer = (state = {}, action) => {
  switch (action.type) {
    case Fetch_Consultation_Request:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const TodaysAppointmentsReducer = (state = {}, action) => {
  switch (action.type) {
    case Fetch_Todays_Appointments_Request:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const AppointmentsReducer = (state = {}, action) => {
  switch (action.type) {
    case Fetch_Appointments_Request:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const AppointmentsCountReducer = (state = {}, action) => {
  switch (action.type) {
    case Fetch_Appointments_Count_Request:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
