import { Button, Col, message, Row } from "antd";
import React from "react";
import { List, Icon, Avatar, Rate } from "antd";
import PieChart from "./Components/PieChart";
import BarCharts from "./Components/ColumnCharts";
import "./Reports.scss";
import LineChart from "./Components/LineChart";
import Axiosinstance from "../../../../config";

const Reports = ({ setReportType, ReviewList, setupdateReview }) => {
  const handleupdateReviews = async (item, val) => {
    let data;

    if (val) {
      data = {
        isApproved: val,
      };
    } else {
      data = {
        isRejected: true,
      };
    }

    try {
      const res = await Axiosinstance.put(
        `/client/doc/v1.0/updateReview/${item._id}`,
        data
      );
      if (res.status === 200) {
        setupdateReview((prestate) => !prestate);
        message.success("Review Updated successfully");
      }
    } catch (error) {}
  };

  return (
    <div className="Reports-Container">
      <Row>
        <Col md={12}>
          <div className="Reports-bar-charts">
            <div>
              <h1>Patients Activity</h1>
              <span>Last updated at 04:30 PM</span>
              <br />
            </div>
            <div style={{ height: "25rem", paddingTop: "2rem" }}>
              <BarCharts />
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="Reports-pie-charts">
            <PieChart />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="Reports-bar-charts">
            <h1>Overview of Revenue</h1>
            <span>Last updated at 04:30 PM</span>
            <br />
            <div style={{ height: "25rem", paddingTop: "1.5rem" }}>
              <LineChart />
            </div>
          </div>
        </Col>

        <Col md={12}>
          <div className="Reports-Container">
            <div className="Reports-Reviews-List">
              <div className="Reviews-Container">
                <h1>Patient Reviews</h1>
                <div className="Reviews-Container-body">
                  <List
                    dataSource={ReviewList}
                    renderItem={(item) => (
                      <>
                        <List.Item key={item._id}>
                          <List.Item.Meta
                            actions={[
                              <Button>Active Review</Button>,
                              <Button>Hide</Button>,
                              ,
                            ]}
                            avatar={
                              item.patientProfileImg ? (
                                <Avatar src={item.patientProfileImg} />
                              ) : (
                                <Avatar className="NoProfileImg">
                                  {item.patientName &&
                                    item.patientName
                                      ?.replace(/[^a-zA-Z0-9]/g, "")
                                      .charAt(0)}
                                </Avatar>
                              )
                            }
                            title={<span>{item.patientName}</span>}
                            description={item.review}
                          />
                        </List.Item>
                        <div className="Review-btn-setup">
                          <Button
                            className="Review-Active"
                            onClick={() => handleupdateReviews(item, true)}
                            disabled={item.isApproved}
                          >
                            Active{item.isApproved && "d"} Review
                          </Button>
                          {!item.isApproved && (
                            <Button
                              className="Review-Hide"
                              onClick={() => handleupdateReviews(item, false)}
                            >
                              Hide
                            </Button>
                          )}
                        </div>
                      </>
                    )}
                  ></List>

                  <br />
                  <br />

                  <h1
                    onClick={() => setReportType("Reviews")}
                    style={{ textAlign: "center", cursor: "pointer" }}
                  >
                    View all
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Reports;
