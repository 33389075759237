import React, { useState, useEffect } from "react";
import {
  Icon,
  Row,
  Button,
  Col,
  Card,
  Table,
  Modal,
  Select,
  Input,
  Tooltip,
} from "antd";
import moment from "moment";
import "./MainDashBoard.scss";
import DashboardLayout from "./../DashboardLayout";
import { useHistory, Link } from "react-router-dom";
import DashboardSideContent from "./DashboardSideContent/DashboardSideContent";
import { useDispatch, useSelector } from "react-redux";
import {
  Appointments_fetch_Action,
  AppointmentsCount_fetch_Action,
  Todays_Appointments_fetch_Action,
  Consultation_fetch_Action,
} from "../../../Redux/Appointments/AppointmentsActions";
import AppointmentOverview from "./Components/AppointmentOverview";
import ConsultationOverview from "./Components/ConsultationOverview";
import CancelledOverview from "./Components/CancelledOverview";
import PendingOverview from "./Components/PendingOverview";
import UpcomingOverView from "./Components/UpcomingOverview";
import MedicationTable from "./Components/MedicationTable";
import AppointmentDetails from "./../DashboardAppointments/Components/AppointmentDetails";
import { Profile_fetch_Action } from "./../../../Redux/Profile/ProfileAction";
import GetlocalStorage from "../../../Common/setLocalStorage/GetlocalStorage";

const MainDashBoard = () => {
  const [Loader, setLoader] = useState(false);
  const [morningAppointments, setMorningAppointments] = useState([]);
  const [afternoonAppointments, setAfternoonAppointments] = useState([]);
  const [evngAppointments, setEvngAppointments] = useState([]);
  const [MedicationModal, setMedicationModal] = useState(false);
  const [InfoModalVisible, setInfoModalVisible] = useState(false);
  const [AppointmentsInfo, setAppointmentsInfo] = useState();

  const history = useHistory();
  const dispatch = useDispatch();
  let getlocalStorage = GetlocalStorage();
  let CareGiverid = getlocalStorage.CareGiverid;
  /*Consultations query params */
  let cosultationDate = moment().format("YYYY-MM-DD HH:mm");
  let chr = cosultationDate.slice(10, 13);
  let cmin = cosultationDate.slice(14, 16);

  useEffect(() => {
    dispatch(
      Todays_Appointments_fetch_Action(
        CareGiverid,
        "Today",
        routercall,
        handleLoader,
        chr.trim(),
        cmin
      )
    );
    dispatch(
      AppointmentsCount_fetch_Action(
        CareGiverid,
        routercall,
        handleLoader,
        chr.trim(),
        cmin
      )
    );
    dispatch(
      Consultation_fetch_Action(
        CareGiverid,
        routercall,
        handleLoader,
        chr.trim(),
        cmin
      )
    );
    dispatch(
      Appointments_fetch_Action(
        CareGiverid,
        "Upcoming",
        routercall,
        handleLoader,
        chr.trim(),
        cmin
      )
    );
    dispatch(
      Appointments_fetch_Action(
        CareGiverid,
        "Upcoming",
        routercall,
        handleLoader,
        chr.trim(),
        cmin
      )
    );
  }, []);

  useEffect(() => {
    dispatch(Profile_fetch_Action(CareGiverid, routercall, handleLoader));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routercall = (val) => {
    // history.push(val);
  };

  const handleLoader = (val) => {
    setLoader(val);
  };

  const AppointmentsData = useSelector(
    (state) => state?.AppointmentsReducer?.data?.Upcoming
  );
  const TodaysAppointmentsData = useSelector(
    (state) => state?.TodaysAppointmentsReducer?.data?.TodayUpcomingRecord
  );
  const AppiontmentsCount = useSelector(
    (state) => state.AppointmentsCountReducer.data
  );
  const consultationsData = useSelector(
    (state) => state.ConsultationsReducer.data
  );
  console.log("AppointmentsDatas", TodaysAppointmentsData);

  useEffect(() => {
    bookings();
  }, [TodaysAppointmentsData]);

  const bookings = () => {
    if (TodaysAppointmentsData && TodaysAppointmentsData.length > 0) {
      TodaysAppointmentsData.map((item, index) => {
        getBookedSlots(item.appointmentSlotTime, item.slotSessionTime);
      });
    } else {
      setMorningAppointments([]);
      setAfternoonAppointments([]);
      setEvngAppointments([]);
    }
  };

  var morningSlots2 = [],
    evngSlots1 = [],
    afternoonSlots1 = [];

  const getBookedSlots = (appointmentSlotTime, slotSessionTime) => {
    var startTime1 = moment(appointmentSlotTime, "HH:mm");
    var morning = moment("12", "HH:mm");
    var afternoon = moment("17", "HH:mm");
    if (
      moment(morning).format("HH:mm") > new moment(startTime1).format("HH:mm")
    ) {
      morningSlots2.push(
        new moment(startTime1).format("HH:mm") +
        "-" +
        new moment(startTime1.add(Number(slotSessionTime), "minutes")).format(
          "HH:mm"
        )
      );
    } else if (
      moment(afternoon).format("HH:mm") > moment(startTime1).format("HH:mm")
    ) {
      afternoonSlots1.push(
        moment(startTime1).format("HH:mm") +
        "-" +
        new moment(startTime1.add(Number(slotSessionTime), "minutes")).format(
          "HH:mm"
        )
      );
    } else if (
      moment(afternoon).format("HH:mm") < moment(startTime1).format("HH:mm")
    ) {
      evngSlots1.push(
        new moment(startTime1).format("HH:mm") +
        "-" +
        new moment(startTime1.add(Number(slotSessionTime), "minutes")).format(
          "HH:mm"
        )
      );
    }
    setMorningAppointments(morningSlots2);
    setAfternoonAppointments(afternoonSlots1);
    setEvngAppointments(evngSlots1);
  };

  const columns = [
    { title: "Patient Name", dataIndex: "Patient_Name", key: "Patient_Name" },
    { title: "Issue", dataIndex: "Issue", key: "Issue" },
    { title: "Appointment Date", dataIndex: "Date", key: "Date" },
    { title: "Slot Timing", dataIndex: "Slot_Timing", key: "Slot_Timing" },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text, record) => (<span className="accepted">Accepted</span>),
    },
    {
      title: "",
      dataIndex: "info",
      render: (text, record) => (
        <Icon
          className="Info-Icon"
          onClick={() => onclick_AppointmentsInfo(record.Info)}
          type="info-circle"
        />
      ),
    },
  ];

  const data = [];

  AppointmentsData?.length > 0 &&
    AppointmentsData.map((item, index) => {
      const { slotSessionTime, appointmentSlotTime } = item;
      var startTime = moment(appointmentSlotTime, "HH:mm");
      data.push({
        Patient_Name: item.patientName,
        Issue: (

          <span>{item.purposeOfVist}</span>

        ),
        Date: moment(item.appointmentDate).format("DD-MM-YYYY"),
        Slot_Timing: `${appointmentSlotTime}- ${moment(startTime.add(Number(slotSessionTime), "minutes")).format("HH:mm")}`,
        Info: item,
      });
    });

  const onclick_AppointmentsInfo = (val) => {
    setAppointmentsInfo(val);
    setInfoModalVisible(true);
  };
 

  return (
    <div>
      <DashboardLayout>
        <div className="MainDashboard-Body">
          <Row>
            <Col lg={18}>
              <div className="MainDashboard-Slot-Timings">
                <div className="Slot-Timings-Today">
                  <h3>Your Slot timings for today :</h3>
                  <p>{moment().format("DD-MM-YYYY, h:mm a")}</p>
                </div>
                {morningAppointments.length === 0 &&
                  afternoonAppointments.length === 0 &&
                  evngAppointments.length === 0 && (
                    <div className="Slot-Timings-NoContent">
                      <h5>No slots Available</h5>
                    </div>
                  )}
                <div className="Slot-Timings-Today-Body">
                  {morningAppointments && morningAppointments.length !== 0 && (
                    <div className="Slot-Timings-Today-Content">
                      <div className="SlotIMG">
                        <img
                          src={require("../../../Assets/morning1.png")}
                          alt="icon1"
                        />
                      </div>
                      <div className="Slot-Timings">
                        {morningAppointments.map((i) => (
                          <Button className="SlotBtn">{i}</Button>
                        ))}
                      </div>
                    </div>
                  )}
                  {afternoonAppointments && afternoonAppointments.length !== 0 && (
                    <div className="Slot-Timings-Today-Content">
                      <div className="SlotIMG">
                        <img
                          src={require("../../../Assets/afternoon1.png")}
                          alt="icon1"
                        />
                      </div>
                      <div className="Slot-Timings">
                        {afternoonAppointments.map((i) => (
                          <Button className="SlotBtn">{i}</Button>
                        ))}
                      </div>
                    </div>
                  )}
                  {evngAppointments && evngAppointments.length !== 0 && (
                    <div className="Slot-Timings-Today-Content2">
                      <div className="SlotIMG">
                        <img
                          src={require("../../../Assets/evening1.png")}
                          alt="icon1"
                        />
                      </div>
                      <div className="Slot-Timings">
                        {evngAppointments.map((i) => (
                          <Button className="SlotBtn">{i}</Button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="MainDashboard-Body-Overview">
                <h3>Overview</h3>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p> */}
                <div className="MainDashboard-Body-Overview-Content">
                  <AppointmentOverview />
                  <ConsultationOverview />
                  <CancelledOverview />
                  <PendingOverview />
                  <UpcomingOverView />
                </div>
              </div>

              {consultationsData && consultationsData.length !== 0 && (
                <div className="MainDashboard-ScheduledAppointment">
                  <h3>Scheduled Appointment</h3>
                  <div className="ScheduledAppointment-Body">
                    <div className="ScheduledAppointment-Content">
                      <div className="DETAILS">
                        <h5>Name</h5>
                        <span>:</span>
                        <h4>
                          {consultationsData && consultationsData.length !== 0
                            ? consultationsData[0].patientName
                            : ""}
                        </h4>
                      </div>
                      <div className="DETAILS">
                        <h5>Slot</h5>
                        <span>:</span>
                        <h4>
                          {consultationsData && consultationsData.length !== 0
                            ? consultationsData[0].appointmentSlot
                            : ""}{" "}
                          (
                          {moment(consultationsData[0].appointmentDate).format(
                            "DD MMM YYYY"
                          )}
                          )
                        </h4>
                      </div>
                      <div className="DETAILS">
                        <h5>Booking ID</h5>
                        <span>:</span>
                        <h4>
                          {consultationsData && consultationsData.length !== 0
                            ? consultationsData[0].bookingId.substring(0, 12)
                            : "CG GIVER"}
                        </h4>
                      </div>
                      <div className="DETAILS">
                        <h5>Issue</h5>
                        <span>:</span>
                        <h4>
                          {consultationsData &&
                            consultationsData.length !== 0 ? (
                           
                              <span>
                                {consultationsData[0].purposeOfVist}
                                
                              </span>
                          
                          ) : (
                            ""
                          )}{" "}
                          <Icon
                            onClick={() =>
                              onclick_AppointmentsInfo(consultationsData[0])
                            }
                            type="info-circle"
                          />
                        </h4>
                      </div>
                    </div>
                    <img
                      className="ScheduledAppointment-Location"
                      src={require("../../../Assets/Location.svg")}
                    />
                  </div>
                </div>
              )}

              <div className="MainDashboard-UpcomingAppointments">
                <h3>Upcoming Appointments</h3>
                <div className="UpcomingAppointments-Table">
                  <Table
                    columns={columns}
                    dataSource={data}
                    loading={Loader}
                    pagination={false}
                  />
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <DashboardSideContent />
            </Col>
          </Row>
        </div>
      </DashboardLayout>

      {/* <Modal className="StatusModal" centered footer={null}
        visible={modalVisible} closable={false} onCancel={() => setmodalVisible(false)}
      >
       <StatusModal bookingRecord={bookingRecord} setmodalVisible={setmodalVisible} 
          setRoomNumber={setRoomNumber} aryDates={aryDates} rooms={rooms}
       />
      </Modal> */}

      <Modal
        className="Medication-Modal"
        visible={MedicationModal}
        centered={true}
        footer={null}
        onCancel={() => setMedicationModal(false)}
        closable={false}
      >
        <Icon
          onClick={() => setMedicationModal(false)}
          className="CloseIcon2"
          type="close-circle"
          theme="filled"
        />
        <MedicationTable
          Appointmentsid={AppointmentsData && AppointmentsData[0]}
          setMedicationModal={setMedicationModal}
        />
      </Modal>

      <Modal
        className="Appointments-InfoModal"
        visible={InfoModalVisible}
        centered={true}
        footer={null}
        onCancel={() => setInfoModalVisible(false)}
        closable={false}
      >
        <Icon
          onClick={() => setInfoModalVisible(false)}
          className="IconClose"
          type="close-circle"
          theme="filled"
        />
        <AppointmentDetails AppointmentInfo={AppointmentsInfo} />
      </Modal>
    </div>
  );
};
export default MainDashBoard;
