import { Row, Col, message, Icon, Spin, Input } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./ResetPassword.scss";
import CustomInput from "../../../../Common/custom-input";
import header_logo from "../../../../Assets/header_logo.png";
import caregiver_illustration from "../../../../Assets/caregiver_illustration.svg";
import Arrowbutton from "../../../../Assets/Arrowbutton.png";
import { useHistory, useLocation } from "react-router-dom";
import { Doctor_Login_reset_Action } from "../../../../Redux/Login/LoginAction";
import Axiosinstance from "./../../../../config";

const initialdata = {
  Code: "",
  password: "",
  confirmPassword: "",
};

const ResetPassword = (props) => {
  const [enable, setenable] = useState(false);
  const [Resetform, setsResetforme] = useState(initialdata);
  const [Loading, setLoading] = useState(false);
  const [screenType, setScreenType] = useState("OTPSCREEN");
  let [BlurCheck, setBlurCheck] = useState({
    Code: false,
    password: false,
    confirmPassword: false,
  });
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();

  const handleinputChange = (e) => {
    const { name, value } = e.target;
    setsResetforme({
      ...Resetform,
      [name]: value,
    });
  };

  const { email } = useLocation();
  useEffect(() => {
    if (!email) {
      history.goBack();
    }
  }, [email]);

  const Routercall = (val) => {
    if (val) {
      message.error(val);
    } else {
      history.push("/");
    }
  };

  const handleLoader = (val) => {
    setLoading(val);
  };

  let isValid = () => {
    let valid = true;
    //reading all controls from 'errors' state
    for (let control in errors) {
      if (errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };
  const handleOtpValidations = async () => {
    if (Code) {
      const data = {
        email: email,
        OTP: Code,
      };
      try {
        const response = await Axiosinstance.post(
          "auth/v1.0/forgotEmailOTP",
          data
        );
        if (response.status === 200) {
          setScreenType("RESETSCREEN");
          message.success("otp valid");
        } else {
          message.error("Please enter valid Otp");
        }
      } catch (error) {
        message.error("Please enter valid Otp");
        console.log("error", error);
      }
    } else {
      message.error("Please enter  Otp");
    }
  };

  const resetPassword = () => {
    let BlurCheckData = BlurCheck;
    Object.keys(BlurCheck).forEach((control) => {
      BlurCheckData[control] = true;
    });
    setBlurCheck(BlurCheckData);
    validate();
    if (isValid()) {
      const data = {
        email: email,
        password: password,
        OTP: Code,
      };
      dispatch(Doctor_Login_reset_Action(data, Routercall, handleLoader));
    }
  };

  const { Code, password, confirmPassword } = Resetform;

  let validate = () => {
    let errorsData = {};
    if (!Code) {
      errorsData.Code = "Code can't be blank";
    }
    //password can't blank
    if (!password) {
      errorsData.password = "Password can't be blank";
    }
    //password regex
    const validPasswordRegex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15})/;
    if (password) {
      if (!validPasswordRegex.test(password)) {
        errorsData.password =
          "Password should be 6 to 15 characters long with at least one uppercase letter, one lowercase letter and one digit";
      }
    }
    if (!confirmPassword) {
      errorsData.confirmPassword = " Confirm Password  can't be blank";
    } else if (password !== confirmPassword) {
      errorsData.confirmPassword =
        "Password and Confirm Password should be same";
    }
    setErrors(errorsData);
  };

  useEffect(validate, [Resetform]);

  useEffect(() => {
    if (Code && password && confirmPassword) {
      setenable(true);
    } else {
      setenable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Resetform]);

  const antIcon = <Icon type="loading" style={{ fontSize: 60 }} spin />;

  return (
    <div
      className="ResetPassword-container"
      style={{ opacity: Loading && "0.5" }}
    >
      <div className="HeaderlogoContainer">
        <img src={header_logo} alt="Logo" />
      </div>
      <div>
        <Row className="Login-flelds-body">
          <Col lg={10}>
            <div className="Login-password-Reset">
              <div className="Login-flelds-inputs">
                <h1 className="Login-Reset-setup">Reset Password</h1>
                {screenType === "OTPSCREEN" && (
                  <>
                    <CustomInput
                      name="Code"
                      placeholder="Enter Code "
                      errorText={BlurCheck["Code"] && errors.Code}
                      value={Code}
                      onChange={handleinputChange}
                      onBlur={(event) => {
                        setBlurCheck({
                          ...BlurCheck,
                          [event.target.name]: true,
                        });
                        validate();
                      }}
                      type="text"
                    />
                    <div className="ResetPassword-Click">
                      <div className="button-next">
                        <img
                          src={Arrowbutton}
                          onClick={handleOtpValidations}
                          style={Code ? { opacity: 1 } : { opacity: 0.5 }}
                          alt="Logo"
                        />
                      </div>
                    </div>
                  </>
                )}
                {screenType === "RESETSCREEN" && (
                  <>
                    <Input.Password
                      name="password"
                      placeholder="Enter New Password"
                      value={password}
                      onChange={handleinputChange}
                      onBlur={(event) => {
                        setBlurCheck({
                          ...BlurCheck,
                          [event.target.name]: true,
                        });
                        validate();
                      }}
                    />
                    {BlurCheck["password"] && errors.password && (
                      <div
                        className="ERROR2"
                        style={{
                          color: "#f44336",
                          fontSize: "1.1rem",
                          textAlign: "left",
                          marginBottom: "0.6em",
                          marginTop: "-0.6em",
                        }}
                      >
                        {BlurCheck["password"] && errors.password}
                      </div>
                    )}
                    {!(BlurCheck["password"] && errors.password) && (
                      <p style={{ marginTop: "0", marginBottom: "1em" }} />
                    )}
                    <Input.Password
                      name="confirmPassword"
                      placeholder="Enter Confirm Password"
                      value={confirmPassword}
                      onChange={handleinputChange}
                      onBlur={(event) => {
                        setBlurCheck({
                          ...BlurCheck,
                          [event.target.name]: true,
                        });
                        validate();
                      }}
                    />
                    {BlurCheck["confirmPassword"] && errors.confirmPassword && (
                      <div
                        className="ERROR2"
                        style={{
                          color: "#f44336",
                          fontSize: "1.1rem",
                          textAlign: "left",
                          marginBottom: "0.6em",
                          marginTop: "-0.6em",
                        }}
                      >
                        {BlurCheck["confirmPassword"] && errors.confirmPassword}
                      </div>
                    )}
                    {!(
                      BlurCheck["confirmPassword"] && errors.confirmPassword
                    ) && <p style={{ marginTop: "0", marginBottom: "1em" }} />}
                    <div className="ResetPassword-Click">
                      <div className="button-next">
                        <img
                          src={Arrowbutton}
                          onClick={resetPassword}
                          style={enable ? { opacity: 1 } : { opacity: 0.5 }}
                          alt="Logo"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <Spin spinning={Loading} indicator={antIcon} />
            </div>
          </Col>
          <Col lg={10}>
            <div className="Loginpage-img">
              <img src={caregiver_illustration} alt="Logo" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ResetPassword;
