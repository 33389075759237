import { Icon, Input, Upload, Spin, Select, message } from "antd";
import React, { useState, useEffect } from "react";
import CustomInput from "./../../../../../Common/custom-input";
import S3 from "react-aws-s3";
import CustomUpload from "../../../../../Common/custom-upload";

const { Option } = Select;
const { TextArea } = Input;

const EditProfessionalinfo = ({
  Informationdata,
  setInformation,
  SpecialitiesResponse,
  setEditResponse,
  EditResponse,
  setBlurCheck,
  BlurCheck,
  errors,
  validate,
  initialResponse,
}) => {
  const [spin, setspin] = useState({ license: false });
  // const [Addinput, setAddinput] = useState();
  const [SpecialitiesList, setSpecialitiesList] = useState([]);
  const [otherSpecialities, setotherSpecialities] = useState();
  const [languagesList, setlanguagesList] = useState();
  const [UpdateSpecialties, setUpdateSpecialties] = useState();
  const [Updatelanguage, setUpdatelanguage] = useState();
  const [Addtext, setAddtext] = useState();
  const [filedata, setfiledata] = useState();
  const {
    // subSpecialtiesName,
    subSpecialtiesID,
    degree,
    summery,
    license,
    languages,
    profileHeader,
  } = Informationdata ?? {};

  useEffect(() => {
    setSpecialitiesList(subSpecialtiesID);
    const result = SpecialitiesResponse?.filter(
      ({ _id: id1 }) => !SpecialitiesList?.some((id2) => id2 === id1),
    );
    setotherSpecialities(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialResponse]);

  useEffect(() => {
    const result = ["English", "French", "German"]?.filter(
      (item1) => !languages?.some((id2) => id2 === item1),
    );
    console.log("result", result);
    setlanguagesList(result);
  }, [languages, Updatelanguage]);

  console.log("languages", languages);

  useEffect(() => {
    setotherSpecialities(SpecialitiesResponse);
  }, [SpecialitiesResponse]);

  useEffect(() => {
    const result = SpecialitiesResponse?.filter(
      ({ _id: id1 }) => !SpecialitiesList?.some((id2) => id2 === id1),
    );
    setotherSpecialities(result);
    const results = SpecialitiesResponse?.filter(({ _id: id1 }) =>
      SpecialitiesList.some((item) => item === id1),
    );
    const SpecialtieNamefilter = results?.map((item) => item.name);
    const SpecialtieIdfilter = results?.map((item) => item._id);
    if (subSpecialtiesID) {
      setInformation({
        ...Informationdata,
        subSpecialtiesName: SpecialtieNamefilter,
        subSpecialtiesID: SpecialtieIdfilter,
      });
      setEditResponse({
        ...EditResponse,
        subSpecialtiesName: SpecialtieNamefilter,
        subSpecialtiesID: SpecialtieIdfilter,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UpdateSpecialties]);

  console.log("SpecialitiesList>>>", SpecialitiesList);
  const handleSelectSpecialitiesChange = (val) => {
    setSpecialitiesList(val);
    setUpdateSpecialties((prestate) => !prestate);
  };

  const handleupload = (info, key) => {
    setspin({
      ...spin,
      [key]: true,
    });
    if (filedata && filedata.size <= 5000000) {
      const newFileName = filedata?.name.replace(/\..+$/, "");
      const config = {
        bucketName: "ub-provider-image-uploader",
        dirName: "host" /* optional */,
        region: "us-west-1",
        accessKeyId: "AKIAW7CW6QVRSTEZBUOO",
        secretAccessKey: "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",
      };
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(filedata, newFileName).then((data) => {
        if (data.status === 204) {
          setInformation({
            ...Informationdata,
            license: data.location,
          });
          setEditResponse({
            ...EditResponse,
            license: data.location,
          });
          setspin({
            ...spin,
            [key]: false,
          });
        } else {
          console.log("fail");
        }
      });
    } else {
      message.error(`${filedata.name} is too large Maximum file size is 5MB`);
      setspin({
        ...spin,
        [key]: false,
      });
    }
  };

  const fileUploadRequest = ({ file, onSuccess }) => {
    setfiledata(file);
  };

  // const handleAddText = (val) => {
  //   setAddinput(true);
  //   if (Addtext) {
  //     setAddinput(false);
  //     setInformation({
  //       ...Informationdata,
  //       subSpecialtiesName: [...subSpecialtiesName, Addtext],
  //     });
  //     // setInternship([
  //     //   ...Internship,
  //     //   AddInternshiptext,
  //     // ]);
  //     setAddtext();
  //   }
  // };

  // const handlespecialitiesTextChange = (i, event) => {
  //   console.log("event.target.value", i, event.target.value);
  //   let newArr = { ...Informationdata };
  //   newArr.specialities[i] = event.target.value;
  //   setInformation(newArr);
  // };

  const handletextChange = (e) => {
    const { name, value } = e.target;
    if (name === "Addtext") {
      setAddtext(value);
    }
    setInformation({
      ...Informationdata,
      [name]: value,
    });
    setEditResponse({
      ...EditResponse,
      [name]: value,
    });
  };

  const handleSelectChange = (e) => {
    setInformation({
      ...Informationdata,
      languages: e,
    });
    setUpdatelanguage((prestate) => !prestate);
    setEditResponse({
      ...EditResponse,
      languages: e,
    });
  };

  return (
    <>
      <div className="editProfessionalData">
        <h2>Edit your professional information</h2>
        <div className="editProfessional_content">
          <div className="personal_info">
            <h5>Profile header</h5>
            <CustomInput
              className="InputCustom"
              name="profileHeader"
              value={profileHeader}
              onChange={handletextChange}
              onBlur={(event) => {
                console.log("object");
                setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                validate();
              }}
            />
          </div>
          {BlurCheck["profileHeader"] && errors.profileHeader && (
            <p className="ERROR">
              {BlurCheck["profileHeader"] && errors.profileHeader}
            </p>
          )}
          <div className="personal_info">
            <h5>Bio/Summary</h5>
            {/* <CustomInput
                className="InputCustom"
                value="15 years experience Operating"
              /> */}
            <TextArea
              maxLength="120"
              value={summery}
              name="summery"
              onChange={handletextChange}
              autoSize={{ minRows: 2 }}
              onBlur={(event) => {
                console.log("object");
                setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                validate();
              }}
            // onChange={handleAddTextChange}
            />
          </div>
          {BlurCheck["summery"] && errors.summery && (
            <p className="ERROR">{BlurCheck["summery"] && errors.summery}</p>
          )}
          <div className="personal_info">
            <h5>Other Specialities </h5>
            {/* <div className="Inputs1">
                <TextArea value="Nephrology & Family Medicine" autoSize={{minRows: 2}}  
                  // onChange={handleAddTextChange} maxLength="120"
                />
              </div>   */}

            <Select
              className="MultiSelect"
              placeholder="Select your Specialities"
              mode="multiple"
              value={SpecialitiesList}
              onChange={handleSelectSpecialitiesChange}
              onBlur={(event) => {
                setBlurCheck({ ...BlurCheck, subSpecialtiesName: true });
                validate();
              }}
            >
              {otherSpecialities?.map((item, i) => (
                <Option key={item} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>

            {/* <Icon type="plus-circle" theme="filled" /> */}
          </div>
          {BlurCheck["SpecialitiesList"] && errors.SpecialitiesList && (
            <p className="ERROR">
              {BlurCheck["SpecialitiesList"] && errors.SpecialitiesList}
            </p>
          )}
          <div className="personal_info">
            <h5>Degrees :</h5>
            <div className="Inputs1">
              <TextArea
                maxLength="120"
                name="degree"
                value={degree}
                onChange={handletextChange}
                autoSize={{ minRows: 2 }}
                onBlur={(event) => {
                  console.log("object");
                  setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                  validate();
                }}
              // onChange={handleAddTextChange}
              />
            </div>
            {/* <Icon type="plus-circle" theme="filled" /> */}
          </div>
          {BlurCheck["degree"] && errors.degree && (
            <p className="ERROR">{BlurCheck["degree"] && errors.degree}</p>
          )}
          <div className="personal_info">
            <h5>Languages</h5>
            <Select
              className="MultiSelect"
              placeholder="Select your Language"
              onChange={handleSelectChange}
              value={languages && languages}
              mode="multiple"
              onBlur={(event) => {
                console.log("object");
                setBlurCheck({ ...BlurCheck, languages: true });
                validate();
              }}
            >
              {languagesList?.map((item, i) => (
                <Option value={item}>{item}</Option>
              ))}
            </Select>
          </div>
          {BlurCheck["languages"] && errors.languages && (
            <p className="ERROR">{BlurCheck["languages"] && errors.languages}</p>
          )}
          <div className="personal_info">
            <h5>Practise License </h5>
            <CustomInput
              className="InputCustom"
              value={license?.substring(license?.lastIndexOf("/") + 1)}
              onBlur={(event) => {
                console.log("object");
                setBlurCheck({ ...BlurCheck, lincense: true });
                validate();
              }}
              suffix={
                !spin.license ? (
                  <CustomUpload
                    setUploadstate={setInformation}
                    setspin={setspin}
                    setEditResponse={setEditResponse}
                    accept={
                      ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                    uploadfrom={"mdloginprocess"}
                    uploadkey={"license"}
                  // email={email}
                  />
                ) : (
                  <Spin className="AccountInfoSPIN" spinning={spin.license} />
                )
              }
            />
          </div>
          {BlurCheck["lincense"] && errors.lincense && (
            <p className="ERROR">{BlurCheck["lincense"] && errors.lincense}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default EditProfessionalinfo;