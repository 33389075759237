import { Row, Col, message, Spin, Icon } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomInput from "../../../../Common/custom-input";
import "./ForgotPassword.scss";
import header_logo from "../../../../Assets/header_logo.png";
import Arrowbutton from "../../../../Assets/Arrowbutton.png";
import caregiver_illustration from "../../../../Assets/caregiver_illustration.svg";
import { Doctor_Login_forgot_Action } from "../../../../Redux/Login/LoginAction";

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [Loading, setLoading] = useState(false);
  let [BlurCheck, setBlurCheck] = useState({
    email: false,
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const inputChange = (e) => {
    setEmail(e.target.value);
  };

  const routercall = (val) => {
    if (val) {
      message.error(val);
    } else {
      history.push({ pathname: "/reset-password",  email });
    }
  };

  const handleLoader = (val) => {
    console.log("VVV", val);
    setLoading(val);
  };

  let isValid = () => {
    let valid = true;
    //reading all controls from 'errors' state
    for (let control in errors) {
      if (errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  const forgotPassword = () => {
    let BlurCheckData = BlurCheck;
    Object.keys(BlurCheck).forEach((control) => {
      BlurCheckData[control] = true;
    });
    setBlurCheck(BlurCheckData);
    validate();
    if (isValid()) {
      dispatch(Doctor_Login_forgot_Action(email, routercall, handleLoader));
    }
  };

  let validate = () => {
    let errorsData = {};
    //email can't blank
    if (!email) {
      errorsData.email = "Email can't be blank";
    }
    //email regex
    const validEmailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (email) {
      if (!validEmailRegex.test(email)) {
        errorsData.email = "Proper email address is expected";
      }
    }
    setErrors(errorsData);
  };

  const signup = () => {
    history.push("/sign-up")
    
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 60 }} spin />;

  return (
    <div className="ForgotPassword-container" style={{ opacity: Loading && "0.5" }}>
      <div className="HeaderlogoContainer">
        <img src={header_logo} alt="Logo" />
      </div>
      <div>
        <Row className="Login-flelds-body">
          <Col lg={10}>
            <div className="Login-Forgot">
              <div className="Login-flelds-inputs">
                <h1>Forgot Password</h1>
                <CustomInput
                  label=""
                  placeholder="Email Address"
                  errorText={BlurCheck["email"] && errors.email}
                  value={email}
                  onBlur={(event) => {
                    setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                    validate();
                  }}
                  onChange={inputChange}
                />
              </div>
            </div>
            <Spin spinning={Loading} indicator={antIcon} />
            <div>
              <div className="Login-button" onClick={forgotPassword}>
                <img
                  src={Arrowbutton}
                  alt="Logo"
                  style={{ opacity: email ? 1 : 0.5 }}
                />
              </div>
              <div className="Forgot-password-check">
                <span>
                  Don’t have an account?&nbsp;
                  <span className="active-color" onClick={signup}>
                    Sign up
                  </span>
                </span>
              </div>
            </div>
          </Col>
          <Col lg={10}>
            <div className="Loginpage-img">
              <img src={caregiver_illustration} alt="Logo" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForgotPassword;
