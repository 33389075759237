import React from "react";
import { Row, Col } from "antd";
import "./ConfirmRegister.scss";
import { Link } from "react-router-dom";

const ConfirmRegister = () => {

  return (
    <div className="ConfirmRegister-Body">
        <Row>
            <Col lg={11}>
                <div className="ConfirmRegister-HeaderImg">
                  <Link to="/">
                    <img src={require("../../../Assets/header_logo.png")} alt="IMG" />
                  </Link>  
                </div>
                <div className="ConfirmRegister-Image">
                    <img src={require('../../../Assets/caregiver_illustration.svg')} alt="IMG" />
                </div>
            </Col>
            <Col lg={12}>
                <div className="ConfirmRegister-Content">
                    <h1>Thank you!</h1>
                    <h1>for Registering with Ubora Provider</h1>
                    <p>“Our Admin team will verify your details and get back to you in 24 hours”</p>
                    <div className="Register-Content">
                        <div className="RegisterDetails">
                            <h2>Facing any issues ? Contact us below</h2>
                            <h3>Tel &nbsp;<span>0123456789</span></h3>
                            <h3>Hotline &nbsp;<span>0123456789</span></h3>
                            <h3>Email &nbsp;<span>support@uborahealth.com</span></h3>
                        </div>
                        <div className="Register-Image">
                            <img src={require('../../../Assets/ios_mockup.png')} alt="IMG" />
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </div>
  );
};

export default ConfirmRegister;