import axios from "axios";
import baseUrl from "./config1";
import { showSessionExpiredModal } from "./utills/SessionExpiredModal";
import { hideLoader, showLoader } from "./utills/loaderUtils";

const Axiosinstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

Axiosinstance.interceptors.request.use(
  (config) => {
    showLoader();
    console.log("config", config.headers);
    config.headers["x-auth"] = localStorage.getItem("auth");
    config.headers["x-token"] = localStorage.getItem("x-token");
    config.headers["access-control-allow-origin"] = "*";
    return config;
  },
  (error) => {
    hideLoader();
    return Promise.reject(error);
  }
);

Axiosinstance.interceptors.response.use(
  (response) => {
    hideLoader();
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    hideLoader();
    if (error?.response?.status) {
      console.log("errorresponsstatus", error?.response);
      if (error?.response?.status == 401) {
        showSessionExpiredModal();
      }
      // eslint-disable-next-line default-case
      switch (error?.response?.status) {
        case 400:
          return error?.response;
        case 401:
          return error?.response;
        case 403:
          return error?.response;
        case 401:
          return error?.response;
        case 404:
          return error?.response;

        case 502:
          return error?.response;
      }
      return Promise.reject(error.response);
    }
  }
);

export default Axiosinstance;
