import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Tabs,
  Icon,
  Avatar,
  Table,
  Badge,
  Modal,
  Button,
  Input,
  message,
  Tooltip,
} from "antd";
import "./DashboardAppointments.scss";
import DashboardLayout from "./../DashboardLayout";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Appointments_fetch_Action,
  Appointments_update_Action,
} from "../../../Redux/Appointments/AppointmentsActions";
import moment from "moment";
import AppointmentDetails from "./Components/AppointmentDetails";
import Axiosinstance from "../../../config";
import GetlocalStorage from "../../../Common/setLocalStorage/GetlocalStorage";

const { TabPane } = Tabs;
const { TextArea } = Input;

const DashboardAppointments = () => {
  const [ViewModalVisible, setViewModalVisible] = useState(false);
  const [currentTab, setcurrentTab] = useState("Pending");
  const [ModalAppointmentsData, setModalAppointmentsData] = useState();
  const [cancelAppointmentModal, setcancelAppointmentModal] = useState(false);
  const [AcceptedModal, setAcceptedModal] = useState(false);
  const [cancelReason, setsCancelReason] = useState();
  const [Loader, setLoader] = useState(false);
  const [patientEmail, setPatientEmail] = useState("");
  // const [AppointmentsData, setAppointmentsData] = useState()
  const [updateid, setUpdateid] = useState();

  const dispatch = useDispatch();
  const history = useHistory();

  /*Consultations query params */
  let cosultationDate = moment().format("YYYY-MM-DD HH:mm");
  let chr = cosultationDate.slice(10, 13);
  let cmin = cosultationDate.slice(14, 16);

  useEffect(() => {
    dispatch(
      Appointments_fetch_Action(
        CareGiverid,
        currentTab,
        routercall,
        handleLoader,
        chr.trim(),
        cmin
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, AcceptedModal, cancelAppointmentModal]);

  const gotoViewModal = (item) => {
    setModalAppointmentsData(item);
    console.log("viewdateils", item);
    setViewModalVisible(true);
  };

  let getlocalStorage = GetlocalStorage();
  let CareGiverid = getlocalStorage.CareGiverid;
  const routercall = (val) => {
    history.push(val);
  };
  const AppointmentsData = useSelector(
    (state) => state.AppointmentsReducer.data
  );

  const handleLoader = (val) => {
    setLoader(val);
  };

  const handleTableAccepted = (record) => {
    setPatientEmail(record.patientEmail);
    setUpdateid(record._id);
    setAcceptedModal(true);
  };

  const handleTableCanceled = (record) => {
    setUpdateid(record._id);
    setPatientEmail(record.patientEmail);
    setcancelAppointmentModal(true);
  };

  const columns = [
    // {
    //   title: "",
    //   dataIndex: "avatar",
    //   key: "avatar",
    //   render: (text, record) => (
    //     <Avatar src={require("../../../Assets/user.svg")} size={54} />
    //   ),
    // },
    { title: "Patient Name", dataIndex: "patientName", key: "patientName" },
    { title: " Email", dataIndex: "patientEmail", key: "patientEmail" },
    {
      title: "Appointment Date",
      dataIndex: "appointmentDate",
      key: "appointmentDate",
      render: (val) => moment(val).format("MMM-DD-YYYY"),
    },
    {
      title: "Slot Timing",
      dataIndex: "slotSessionTime",
      key: "slotSessionTime",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (value, Item) => {
        return (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {currentTab === "Pending" ? (
                <>
                  <Tooltip title="Accept" overlayClassName="md_booking">
                    <Icon
                      className="CheckIcon"
                      type="check-circle"
                      theme="filled"
                      onClick={() => handleTableAccepted(Item)}
                    />
                  </Tooltip>
                  <Tooltip title="Cancel" overlayClassName="md_booking">
                    <Icon
                      className="CloseIcon"
                      onClick={() => handleTableCanceled(Item)}
                      type="close-circle"
                      theme="filled"
                    />
                  </Tooltip>
                  <Tooltip title="View" overlayClassName="md_booking">
                    <Icon
                      className="InfoIcon"
                      type="eye"
                      onClick={() => gotoViewModal(Item)}
                    />
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="View" overlayClassName="md_booking">
                  <Icon
                    className="InfoIcon"
                    type="eye"
                    onClick={() => gotoViewModal(Item)}
                  />
                </Tooltip>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const [appointmentDate, setAppointmentsData] = useState([]);

  const handleTabchange = (key) => {
    console.log("ktabeys", key);
    setcurrentTab(key);
  };

  const fetchAppointments = async () => {
    // const tab = currentTab === "Upcoming" ? "Accepted" : currentTab;
    let cosultationDate = moment().format("MM/DD/YYYY HH:mm");
    let chr = cosultationDate.slice(10, 13).trim();
    let cmin = cosultationDate.slice(14, 16);
    try {
      // const url = `/appointment/v1.0/getCGAppointment/${CareGiverid}`;
      const url = `/appointment/v1.0/getAppointments/${currentTab}/${CareGiverid}?chr=${chr}&cmin=${cmin}`;
      const res = await Axiosinstance.get(url);
      console.log("ressssss", res);
      if (res.status >= 200 && res.status <= 300) {
        setAppointmentsData(res.data.data?.[currentTab]);
      }
    } catch (err) {
      message.info("Something went wrong");
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, [currentTab]);


  const AppointmentRouter = (val) => {
    if (val === "/") {
      history.push(val);
    } else {
      dispatch(
        Appointments_fetch_Action(
          CareGiverid,
          currentTab,
          routercall,
          handleLoader
        )
      );
      setcurrentTab("Upcoming");
      setAcceptedModal(val);
      setcancelAppointmentModal(val);
    }
  };

  const handleAppointmentCancel = () => {
    if (cancelReason && cancelReason.length > 4) {
      const data = {
        email: patientEmail,
        isAccepted: false,
        isDeclained: true,
        declainedReason: cancelReason,
      };
      dispatch(
        Appointments_update_Action(
          updateid,
          data,
          AppointmentRouter,
          handleLoader
        )
      );
    } else if (!cancelReason) {
      message.error("Reason Should mention");
    } else if (cancelReason && cancelReason.length < 4) {
      message.error("Reason can't short");
    }
  };

  const handleAppointmentAccepted = () => {
    const data = {
      email: patientEmail,
      isAccepted: true,
      isDeclained: false,
    };
    dispatch(
      Appointments_update_Action(
        updateid,
        data,
        AppointmentRouter,
        handleLoader
      )
    );
  };

  const handleReasonChange = (e) => {
    setsCancelReason(e.target.value);
  };

  console.log("ModalAppointmentsData", ModalAppointmentsData);
  const fullName = getlocalStorage.fullName;
  const specialtiesName = getlocalStorage.specialtiesName;
  const profilePhoto = getlocalStorage.profilePhoto;

  return (
    <div>
      <DashboardLayout>
        <div className="DashboardAppointments-Body">
          <div className="DashboardAppointments-Body-Content">
            <h3>Appointments</h3>
            <div className="DashboardAppointments_ProfileInfo">
              <Icon
                style={{ visibility: "hidden" }}
                type="caret-down"
                theme="filled"
              />
              <h3 onClick={() => history.push("/dashboardsettings/accountinfo")}>
                {fullName}
              </h3>
              <Badge offset={[-10, 8]} color={"#B0779A"} dot>
                <Icon
                  onClick={() => history.push("/dashboardsettings/notifications")}
                  type="bell"
                  theme="filled"
                />
              </Badge>
            </div>
            <div className="DashboardAppointments-Tabs">
              <Tabs
                defaultActiveKey="Pending"
                onChange={handleTabchange}
                activeKey={currentTab}
              >
                <TabPane tab="Pending" key="Pending">
                  <div className="UpcomingContent-Body">
                    {/* {UpcomingContent} */}
                    <div className="UpcomingContent-Body-Table">
                      <Table
                        columns={columns}
                        dataSource={appointmentDate}
                        loading={Loader}
                        pagination={{ pageSize: 8, size: "small" }}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Upcoming" key="Upcoming">
                  <div className="UpcomingContent-Body">
                    {/* {UpcomingContent} */}
                    <div className="UpcomingContent-Body-Table">
                      <Table
                        columns={columns}
                        dataSource={appointmentDate}
                        pagination={{ pageSize: 8, size: "small" }}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Completed" key="Completed">
                  <div className="UpcomingContent-Body">
                    {/* {UpcomingContent} */}
                    <div className="UpcomingContent-Body-Table">
                      <Table
                        columns={columns}
                        dataSource={appointmentDate}
                        pagination={{ pageSize: 8, size: "small" }}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Cancelled" key="Cancelled">
                  <div className="UpcomingContent-Body">
                    {/* {UpcomingContent} */}
                    <div className="UpcomingContent-Body-Table">
                      <Table
                        columns={columns}
                        dataSource={appointmentDate}
                        pagination={{ pageSize: 8, size: "small" }}
                      />
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
          

          {/* <Col lg={6}>
              <div className="Dashboard-SideContent">
                <div className="Dashboard-SideContent-DoctorInfo">
                  <div className="DoctorInfo-Icons">
                    <Badge offset={[-10, 8]} color={"#B0779A"} dot>
                      <Icon
                        onClick={() =>
                          history.push("/dashboardsettings/notifications")
                        }
                        type="bell"
                        theme="filled"
                      />
                    </Badge>
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <Link to="/dashboardsettings/accountinfo">
                      {profilePhoto !== "null" &&
                        profilePhoto !== "undefined" ? (
                        <Avatar size={114} src={profilePhoto} />
                      ) : (
                        <Avatar className="NoProfileImg" size={114}>
                          {fullName
                            ? fullName?.replace(/[^a-zA-Z0-9]/g, "").charAt(0)
                            : "U"}
                        </Avatar>
                      )}
                      <h1>{fullName}</h1>
                      <p>{specialtiesName}</p>
                    </Link>
                  </div>
                </div>
              </div>
             
            </Col> */}


          <Modal
            className="DashboardAppointments-ViewModal"
            visible={ViewModalVisible}
            centered={true}
            footer={null}
            onCancel={() => setViewModalVisible(false)}
            closable={false}
          >
            <Icon
              onClick={() => setViewModalVisible(false)}
              className="IconClose"
              type="close-circle"
              theme="filled"
            />
            <AppointmentDetails AppointmentInfo={ModalAppointmentsData} />
          </Modal>



          <Modal
            className="CancelBooking-Modal"
            visible={cancelAppointmentModal}
            centered={true}
            footer={null}
            onCancel={() => setcancelAppointmentModal(false)}
            closable={false}
          >
            <Icon
              onClick={() => setcancelAppointmentModal(false)}
              className="IconClose"
              type="close-circle"
              theme="filled"
            />
            <div className="cancelAppointment_modal">
              <h4>Do you want to cancel this Appointment?</h4>
              <TextArea
                placeholder="Type your issue to cancel here"
                // rows={15}
                autoSize={{ minRows: 6 }}
                value={cancelReason}
                onChange={handleReasonChange}
              />
              <div className="BtnSetup">
                <Button
                  className="submitBtn"
                  type="primary"
                  onClick={handleAppointmentCancel}
                >
                  Yes, Cancel
                </Button>
                <Button
                  className="CancelBtn"
                  onClick={() => setcancelAppointmentModal(false)}
                >
                  No, Exit
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            className="Accepted_Modal"
            centered
            footer={null}
            visible={AcceptedModal}

            onCancel={() => setAcceptedModal(false)}
            closeIcon={
              <Icon
                type="close"
                style={{
                  color: "#b0779a",
                }}
              />
            }
          >
            <h2>Do you want to accept this Appointment?</h2>
            <div className="Accepted-Btns">
              <Button
                onClick={handleAppointmentAccepted}
                className="RemoveBtn"
                type="primary"
              >
                Yes, Accept
              </Button>
              <Button
                onClick={() => setAcceptedModal(false)}
                className="CancelBtn"
              >
                No, Cancel
              </Button>
            </div>
          </Modal>
        </div>
      </DashboardLayout>
    </div>
  );
};
export default DashboardAppointments;
