export const setLoginDetails = ({
  _id,
  email,
  specialtiesId,
  auth,
  token,
  isProfileCompleted,
  profilePhoto,
  specialtiesName,
  fullName,
}) => {
  localStorage.setItem("CareGiverid", _id);
  localStorage.setItem("userEmail", email);
  localStorage.setItem("specialtiesId", specialtiesId);
  localStorage.setItem("auth", auth);
  localStorage.setItem("x-token", token);
  if (isProfileCompleted) {
    localStorage.setItem("isCaregiverLogged", true);
  }
  localStorage.setItem("profilePhoto", profilePhoto);
  localStorage.setItem("specialtiesName", specialtiesName);
  localStorage.setItem("fullName", fullName);
};

export const profilePhotoSetter = (value) => {
  localStorage.setItem("profilePhoto", value);
};

export const isMdloggedSetter = (value) => {
  localStorage.setItem("isMdlogged", value);
};

export const servicesNameSetter = (value) => {
  localStorage.setItem("servicesName", value);
};
export const servicesIDSetter = (value) => {
  localStorage.setItem("servicesID", value);
};

export const isCaregiverLoggedSetter = (value) => {
  localStorage.setItem("isCaregiverLogged", true);
};
