import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import "./ProfileInfo.scss";
import PhotoAvatar from "./Components/PhotoAvatar";
import MyInformation from "./Components/MyInformation";
import Education from "./Components/Education";
import Accomplishment from "./Components/Accomplishment";
import { useDispatch } from "react-redux";
import { Profile_CreatePro_Action } from "../../../Redux/Profile/ProfileAction";
import Axiosinstance from "../../../config";
import { useHistory } from "react-router-dom";
import GetlocalStorage from "../../../Common/setLocalStorage/GetlocalStorage";

const ProfileInfo = () => {
  const [profileinfo, setprofileinfo] = useState("Avatar");
  const [profilePhoto, setprofilePhoto] = useState();
  const [enable, setenable] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [SpecialitiesResponse, setSpecialitiesResponse] = useState();

  const history = useHistory();
  const dispatch = useDispatch();

  const [Information, setInformation] = useState({
    subSpecialtiesName: [],
    subSpecialtiesID: [],
    degree: "",
    languages: [],
    lincense: "",
    summery: "",
    profileHeader: "",
  });

  const [Educationinfo, setEducationinfo] = useState({
    medicalSchool: "",
    intership: [],
    fellowship: [],
    residency: "",
  });

  const [Accomplishmentinfo, setAccomplishment] = useState({
    Certifications: [],
    ProMemberships: [],
    Awards: [],
  });

  let getlocalStorage = GetlocalStorage();

  const CareGiverid = getlocalStorage.CareGiverid;
  const spid = getlocalStorage.specialtiesId;
  const specialtiesName = getlocalStorage.specialtiesName;

  const Getsubspecialties = async () => {
    try {
      const res = await Axiosinstance.get(
        `profile/v1.0/subspecialties/${CareGiverid}/${spid}`
      );
      if (res.status === 200) {
        setSpecialitiesResponse(res.data.data);
      } else if (res.status === 401) {
        history.push("/");
      }
    } catch (error) {
      console.info("error", error);
    }
  };

  useEffect(() => {
    Getsubspecialties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    profileHeader,
    subSpecialtiesName,
    degree,
    subSpecialtiesID,
    languages,
    lincense,
    summery,
  } = Information;

  const { medicalSchool, intership, fellowship, residency } = Educationinfo;

  const { Certifications, ProMemberships, Awards } = Accomplishmentinfo;

  const Routercall = () => {
    history.push("/maindashboard");
  };

  const handleCreateProfile = () => {
    const data = {
      profileHeader: profileHeader,
      profilePhoto: profilePhoto,
      summery: summery,
      degree: degree,
      license: lincense,
      medicalSchool: medicalSchool,
      internship: intership,
      fellowship: fellowship,
      residency: residency,
      certification: Certifications,
      profMembership: ProMemberships,
      awards: Awards,
      subSpecialtiesName: subSpecialtiesName,
      subSpecialtiesID: subSpecialtiesID,
      specialtiesID: spid,
      specialtiesName: specialtiesName,
      languages: languages,
    };
    console.log("object", data);
    dispatch(Profile_CreatePro_Action(CareGiverid, data, Routercall));
  };

  return (
    <div className="ProfileInfo-Container" style={{ opacity: Loading && 0.5 }}>
      <div className="ProfileInfo-Container-Headerlogo">
        <img src={require("../../../Assets/header_logo.png")} alt="Logo" />
        <div
          className="ProfileInfo-dot-setup"
          style={{ right: profileinfo === "Avatar" && "28.5%" }}
        >
          <div
            className={profileinfo === "Avatar" ? "active-dot" : "disable-dot"}
          ></div>
          <div
            className={
              profileinfo === "MyInformation" ? "active-dot" : "disable-dot"
            }
          ></div>
          <div
            className={
              profileinfo === "Education" ? "active-dot" : "disable-dot"
            }
          ></div>
          <div
            className={
              profileinfo === "Accomplishment" ? "active-dot" : "disable-dot"
            }
          ></div>
        </div>
      </div>

      <Row>
        <Col lg={14} sm={24}>
          <div className="ProfileInfo-Container-Heading">
            <h3>“We need to ask some questions to ensure we</h3>
            <h3>provide patients your info”</h3>
          </div>
          <div className="ProfileInfo-Container-Img">
            {profileinfo === "Avatar" && (
              <img
                src={require("../../../Assets/caregiver_illustration.svg")}
                alt="Logo"
              />
            )}
            {profileinfo === "MyInformation" && (
              <img
                className="MyInfoImg"
                src={require("../../../Assets/MyinfoBG.svg")}
                alt="Logo"
              />
            )}
            {profileinfo === "Education" && (
              <img
                className="EducationImg"
                src={require("../../../Assets/EducationBG.svg")}
                alt="Logo"
              />
            )}
            {profileinfo === "Accomplishment" && (
              <img
                className="AccomplishmentImg"
                src={require("../../../Assets/AccomplishmentBG.svg")}
                alt="Logo"
              />
            )}
          </div>
        </Col>

        <Col lg={10} sm={24}>
          <div className="ProfileInfo-Container-Details">
            {profileinfo === "Avatar" && (
              <PhotoAvatar
                setprofileinfo={setprofileinfo}
                setprofilePhoto={setprofilePhoto}
                profilePhoto={profilePhoto}
              />
            )}

            {profileinfo === "MyInformation" && (
              <MyInformation
                setprofileinfo={setprofileinfo}
                Informationdata={Information}
                setInformation={setInformation}
                SpecialitiesResponse={SpecialitiesResponse}
                setLoading={setLoading}
                Loading={Loading}
              />
            )}

            {profileinfo === "Education" && (
              <Education
                setprofileinfo={setprofileinfo}
                Educationdata={Educationinfo}
                setEducation={setEducationinfo}
                setLoading={setLoading}
                Loading={Loading}
              />
            )}

            {profileinfo === "Accomplishment" && (
              <Accomplishment
                setprofileinfo={setprofileinfo}
                Accomplishmentdata={Accomplishmentinfo}
                setAccomplishment={setAccomplishment}
                setprofilePhoto={setprofilePhoto}
                setenable={setenable}
                handleCreateProfile={handleCreateProfile}
              />
            )}
            {/* {profileinfo === "Accomplishment"&&
               <div className="NextBTN">
        <img
          src={require("../../../Assets/Arrowbutton.png")}
          alt="btn1"

          onClick={handleCreateProfile}

          // onClick={() => history.push("/maindashboard")}
        />
      </div>} */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileInfo;
