import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const PendingOverview = () => {
  const AppiontmentsCount = useSelector(
    (state) => state.AppointmentsCountReducer.data,
  );

  const [UrgentResolve, setUrgentResolve] = useState({
    series: [
      {
        name: "series1",
        data: [34, 40, 28, 52, 42, 109, 100],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#45B656"],
      xaxis: {
        lines: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  });

  return (
    <div className="Overview-Details-3">
      <div className="Overview-Details-Title">
        {/* <h4>Urgent Resolve</h4> */}
        <h4>Pending</h4>
        {/* <p className="OverviewDay">Today</p> */}
      </div>
      <div className="Overview-Details-Count">
      <img src={require("../../../../Assets/Group 13349.svg")} alt=""/>
        <h1>
          {AppiontmentsCount &&
            (AppiontmentsCount.pending === undefined
              ? 0
              : AppiontmentsCount.pending)}
        </h1>
        {/* <p style={{ color: "#45B656" }} className="OverviewDay-Count">
          +25
        </p> */}
      </div>
      {/* <ReactApexChart
        options={UrgentResolve.options}
        series={UrgentResolve.series}
        type="area"
        height="80"
      /> */}
        {/* <img src={require("../../../../Assets/Group 13349.svg")} alt=""/> */}
    </div>
  );
};

export default PendingOverview;
