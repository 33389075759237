const GetlocalStorage = () => {
  return {
    CareGiverid: localStorage.getItem("CareGiverid"),
    userEmail: localStorage.getItem("userEmail"),
    specialtiesId: localStorage.getItem("specialtiesId"),
    auth: localStorage.getItem("auth"),
    token: localStorage.getItem("x-token"),
    isCaregiverLogged: localStorage.getItem("isCaregiverLogged"),
    profilePhoto: localStorage.getItem("profilePhoto"),
    specialtiesName: localStorage.getItem("specialtiesName"),
    fullName: localStorage.getItem("fullName"),
    profilePhoto: localStorage.getItem("profilePhoto"),
    isMdlogged: localStorage.getItem("isMdlogged"),
    servicesName: localStorage.getItem("servicesName"),
    servicesID: localStorage.getItem("servicesID"),
  };
};
export default GetlocalStorage;
