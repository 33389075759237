import React, { useState, useEffect } from "react";
import { Icon, Table, Select, Input, Button, message } from "antd";
import Axiosinstance from "../../../../config";
import GetlocalStorage from "../../../../Common/setLocalStorage/GetlocalStorage";

const { Option } = Select;

const initialState = {
  type: undefined,
  name: "",
  dosage: undefined,
  duration: "",
  instructions: "",
};

const MedicationTable = ({ Appointmentsid, setMedicationModal }) => {
  const [Medicationinfo, setMedicationinfo] = useState(initialState);
  const [Medicationtype, setMedicationtype] = useState();
  const [Medicationdata, setMedicationdata] = useState([]);

  const { type, name, dosage, duration, instructions } = Medicationinfo;

  // console.log("Appointmentsid", Appointmentsid._id);

  const GetMedicationtype = async () => {
    try {
      const res = await Axiosinstance.get("appointment/v1.0/medicationtype");
      setMedicationtype(res.data.data);
      console.log("res", res.data.data);
    } catch (error) {}
  };

  const AddMedicationinfo = () => {
    if (type && name && dosage && duration && instructions) {
      setMedicationdata([...Medicationdata, Medicationinfo]);
      setMedicationinfo(initialState);
    } else {
      message.error("please fill all the details");
    }
  };

  let getlocalStorage = GetlocalStorage();
  const CareGiverid = getlocalStorage.CareGiverid;

  const CreateMedicationinfo = async () => {
    if (Medicationdata.length !== 0) {
      const data = {
        docProfileId: CareGiverid,
        medication: Medicationdata,
      };
      try {
        const res = await Axiosinstance.post(
          `appointment/v1.0/medication/${Appointmentsid._id}`,
          data
        );
        if (res.status === 200) {
          setMedicationModal(false);
          setMedicationinfo(initialState);
        }
        // setMedicationtype(res.data.data)
        console.log("res", res.data.data);
      } catch (error) {}
    } else {
      message.info("No medication found");
    }
  };

  const handleinputChange = (e) => {
    const { name, value } = e.target;
    setMedicationinfo({
      ...Medicationinfo,
      [name]: value,
    });
  };

  const handleSelectChange = (e, key) => {
    setMedicationinfo({
      ...Medicationinfo,
      [key]: e,
    });
  };

  useEffect(() => {
    GetMedicationtype();
  }, []);

  const Medicationcolumns = [
    {
      title: (
        <Select
          className="MedicationTable-Select"
          onChange={(e) => handleSelectChange(e, "type")}
          value={type}
          placeholder="Type of Medicine"
        >
          {Medicationtype?.map((item) => (
            <Option value={item.name}>{item.name}</Option>
          ))}
        </Select>
      ),
      dataIndex: "type",
      key: "type",
    },
    {
      title: (
        <Input
          className="MedicationTable-Input"
          value={name}
          name="name"
          onChange={handleinputChange}
          placeholder="Name of medicine"
        />
      ),
      dataIndex: "name",
      key: "name",
    },
    {
      title: (
        <Select
          className="MedicationTable-Select2"
          onChange={(e) => handleSelectChange(e, "dosage")}
          placeholder="Dosage"
          value={dosage}
        >
          <Option value="MORNING">Morning</Option>
          <Option value="AFTERNOON">AfterNoon</Option>
          <Option value="NIGHT">Night</Option>
        </Select>
      ),
      dataIndex: "dosage",
      key: "dosage",
    },
    {
      title: (
        <Input
          className="MedicationTable-Input Duration"
          value={duration}
          name="duration"
          onChange={handleinputChange}
          placeholder="Duration"
        />
      ),
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: (
        <Input
          className="MedicationTable-Input"
          placeholder="Instructions"
          name="instructions"
          onChange={handleinputChange}
          value={instructions}
        />
      ),
      dataIndex: "instructions",
      key: "instructions",
    },
  ];

  return (
    <div className="MedicationModal-Body">
      <div className="MedicationModal-Body-Table">
        {/* <h2>Medication</h2> */}
        <Icon type="plus-circle" theme="filled" onClick={AddMedicationinfo} />
        <Table
          columns={Medicationcolumns}
          dataSource={Medicationdata}
          pagination={false}
        />
      </div>
      <div className="Send-button-setup">
        <Button className="info-Send" onClick={CreateMedicationinfo}>
          Send
        </Button>
      </div>
    </div>
  );
};

export default MedicationTable;
