import { Icon, Row, Col, List, Tooltip, Card, Modal, Button } from "antd";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import moment from "moment";
import MedicationTable from "../../MainDashBoard/Components/MedicationTable";
import GetlocalStorage from "../../../../Common/setLocalStorage/GetlocalStorage";

const AppointmentDetails = ({ AppointmentInfo }) => {
  const {
    patientName,
    purposeOfVist,
    appointmentSlotTime,
    slotSessionTime,
    servicesName,
    patientAddress,
    appointmentDate,
    docName,
    patientPreConditions,
    allergicToMedicine,
    currentlyTakingAnyMedication,
    expectingPregnancy,
    hasLabReport,
    patientContactNumber,
    labReports,
    treatmentForLast60days,
    updatedAt,
    tokenId,
    isAccepted,
    isDeclained,
    dob,
    userDOB,
    gender,
  } = AppointmentInfo ?? {};

  let getlocalStorage = GetlocalStorage();
  const [MedicationModal, setMedicationModal] = useState(false);
  var startTime = moment(appointmentSlotTime, "HH:mm");

  const formatDisplayPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = '+1 ';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  const getModeDisplay = (mode) => {
    switch (mode) {
      case 'CAREGIVER': return 'Care Giver';
      case 'HOMEVISIT': return 'Home Visit';
      default: return 'Care Giver';
    }
  };

  console.log("AppointmentInfo", AppointmentInfo);

  return (
    <div className="AppointmentDetails_Container">
      <h3> Patient Details</h3>
      <div>

        <div className="viewAppointment_details">
          <div className="view_booking_content">
            <div className="view_booking_info">
              <h5>Patient Name</h5>
              <span>:</span>
              <h4>{patientName}</h4>
            </div>
            <div className="view_booking_info">
              <h5>Issue</h5>
              <span>:</span>
              <h4 >
                <span>{purposeOfVist}</span>
              </h4>
            </div>
          </div>
          <div className="view_booking_content">
            <div className="view_booking_info">
              <h5 > DOB </h5>
              <span>:</span>
              <h4>
                {moment(userDOB).format("MMM-DD-YYYY")}
              </h4>
            </div>
            <div className="view_booking_info">
              <h5> Age </h5>
              <span>:</span>
              <h4 >
                {moment().diff(userDOB, "years")}
              </h4>
            </div>

          </div>
          <div className="view_booking_content">

            <div className="view_booking_info">
              <h5 >Appointment Date</h5>
              <span>:</span>
              <h4>
                {moment(appointmentDate).format("MMM-DD-YYYY")}
              </h4>
            </div>
            <div className="view_booking_info">
              <h5>Mode</h5>
              <span>:</span>
              <h4>{getModeDisplay(servicesName)}</h4>
            </div>
          </div>
          <div className="view_booking_content">

            <div className="view_booking_info">
              <h5>Contact Number</h5>
              <span>:</span>
              <h4><span>{formatDisplayPhoneNumber(patientContactNumber)}</span></h4>
            </div>
            <div className="view_booking_info">
              <h5>Address</h5>
              <span>:</span>
              <h4 >{patientAddress}</h4>
            </div>
          </div>
          <div className="view_booking_content">
            <div className="view_booking_info">
              <h5>Slot</h5>
              <span>:</span>
              <h4 >
                {`${appointmentSlotTime}- ${moment(
                  startTime.add(Number(slotSessionTime), "minutes")
                ).format("HH:mm")}`}
              </h4>
            </div>
            <div className="view_booking_info">
              <h5>Booking Date</h5>
              <span>:</span>
              <h4>
                {moment(updatedAt).format("MMM-DD-YYYY")}
              </h4>
            </div>
          </div>

        </div>
      </div>

      <div className="Basic_Questions">
        <h3>Basic answered questions by patient</h3>
        <h4>
          Have you had any treatment for the same condition in last 60 days ?
        </h4>
        <div className="Questions-Content">
          <Icon type="up-circle" theme="filled" />
          <h5>
            {treatmentForLast60days ? "Yes" : "No"}
          </h5>
        </div>
        <h4>
          Are you pregnant or think you maybe expecting ?
        </h4>
        <div className="Questions-Content">
          <Icon type="up-circle" theme="filled" />
          <h5>{expectingPregnancy ? "Yes" : "No"}</h5>
        </div>
        <h4>Are you allergic to any medications ?</h4>
        <div className="Questions-Content">
          <Icon type="up-circle" theme="filled" />
          <h5>{allergicToMedicine ? "Yes" : "No"}</h5>
        </div>
        <h4>Are you taking any medications ?</h4>
        <div className="Questions-Content">
          <Icon type="up-circle" theme="filled" />
          <h5 >
            {currentlyTakingAnyMedication ? "Yes" : "No"}
          </h5>
        </div>
        <h4>
          Any other patient information with your provider ?
        </h4>
        <div className="Questions-Content">
          <Icon type="up-circle" theme="filled" />
          <h5 >{hasLabReport ? "Yes" : "No"}</h5>
        </div>
        <h4>
          Do you have any one of the following conditions ?
        </h4>
        <div className="Questions-Content">
          {patientPreConditions?.map((item) => (
            <>
              <Icon type="up-circle" theme="filled" key={item} />
              <h5 className="Sub-Content">{item}</h5>
            </>
          ))}
        </div>
      </div>

      <div className="Upload-Body">
        <h3 >Uploads</h3>
        <List
          grid={{ column: 2 }}
          dataSource={labReports}
          renderItem={(item) => (
            <List.Item>
              <div className="Upload-Body-Content">
                <h3>{item.reportDescription}</h3>
                <div className="FileContent">
                  <h3>:</h3>
                  <div className="Uploadedfile">
                    <a
                      href={process.env.PUBLIC_URL + item.reportFile}
                      target="_blank"
                    >
                      <p>
                        <Icon type="paper-clip" />
                        {item.reportFile.substring(
                          item.reportFile.lastIndexOf("/") + 1
                        )}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>

      <div className="Patient-Messages-Content">
        <h3 className="AppointmentDetails-Title">Message from patient</h3>
        <h3>
          Hi Doctor <br />
          This is {patientName}, I'm suffering from {purposeOfVist}.
        </h3>
      </div>
      {moment(appointmentDate).format("MM/DD/YYYY") ===
        moment().format("MM/DD/YYYY") &&
        isAccepted === true &&
        isDeclained === false ? (
        <div className="Appointment_MeetingCard">
          <div className="MeetingCard_content">
            {/* <h4>Reminder meeting:</h4> */}
            {/* <h4>
            Consultation
            {appointmentSlotTime}
          </h4> */}
            <div className="Meetingcard-Btns">
              <Link
                to={`/videoCall/${getlocalStorage.fullName}/${tokenId}`}
                target="_blank"
              >
                <Button className="startBtn" type="primary">
                  Start Consultation
                </Button>
              </Link>
              <Button
                className="medicationBtn"
                //type="primary"
                // disabled={
                //   new Date() <
                //   new Date(
                //     consultationsData[0].appointmentDate
                //   ).setHours(
                //     Number(
                //       consultationsData[0].appointmentSlotTime.slice(
                //         0,
                //         2
                //       )
                //     ),
                //     Number(
                //       consultationsData[0].appointmentSlotTime.slice(
                //         3,
                //         5
                //       ),
                //       0,
                //       0
                //     )
                //   )
                // }
                onClick={() => setMedicationModal(true)}
              >
                Medication
              </Button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Modal
        className="Medication-Modal"
        visible={MedicationModal}
        centered={true}
        footer={null}
        onCancel={() => setMedicationModal(false)}
        closable={false}
      >
        <Icon
          onClick={() => setMedicationModal(false)}
          className="CloseIcon2"
          type="close-circle"
          theme="filled"
        />
        <MedicationTable
          Appointmentsid={AppointmentInfo}
          setMedicationModal={setMedicationModal}
        />
      </Modal>
    </div>
  );
};

export default AppointmentDetails;
