import React from "react";
import { Redirect, Route } from "react-router";



export const PrivateRoute = ({ component: Component, ...rest }) => {
  let islogin
  if("isCaregiverLogged" in localStorage){
    islogin=true;
  }else{
    islogin=false;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        islogin===false ? (
          <Redirect to="/" />
        ) : (
             <Component {...props} />
        )
      }
    />
  );
};
export const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  let islogin
  if("isCaregiverLogged" in localStorage){
    islogin=true;
  }else{
    islogin=false;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        islogin===true ? (
          <Redirect to="/maindashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

