import moment from "moment";
import React from "react";

const ViewReview = ({ viewReviews }) => {
  console.log("viewReviews", viewReviews);

  const ReviewContent = ({ title, value }) => (
    <div className="view_reviews_info">
      <h5>{title}</h5>
      <span>:</span>
      <h4 className={title === "patient Email" ? "email_text" : ""}>{value}</h4>
    </div>
  );

  return (
    <div className="view_reviews_content">
      <ReviewContent title="patient Name" value={viewReviews?.patientName} />
      <ReviewContent title="patient Email" value={viewReviews?.patientEmail} />
      <ReviewContent title="Rating" value={viewReviews?.rating} />
      <ReviewContent
        title="Reviewed date"
        value={moment(viewReviews?.created_at).format("MMM-DD-YYYY")}
      />
      <ReviewContent title="Comment" value={viewReviews?.review} />
    </div>
  );
};

export default ViewReview;
