import { Select, DatePicker } from "antd";
import React from "react";
import moment from "moment";
import CustomInput from "./../../../../../Common/custom-input";
import AutoLocation from "../../../../../Common/AutoLocation";

const { Option } = Select;
const dateFormat = "MM-DD-YYYY"

const EditPersonalinfo = ({
  setProfileInfo,
  ProfileInfo,
  setEditResponse,
  EditResponse,
  setBlurCheck,
  BlurCheck,
  errors,
  validate,
}) => {
  console.log("ProfileInfo", ProfileInfo);

  const handleInputChange = (event) => {
    if (event.target.name === "zip") {
      setEditResponse({
        ...EditResponse,
        [event.target.name]: Number(event.target.value),
        location: {
          ...EditResponse.location,
          address: {
            zipCode: Number(event.target.value),
          },
        },
      });
      setProfileInfo({
        ...ProfileInfo,
        [event.target.name]: event.target.value,
      });
      setEditResponse({
        ...EditResponse,
        [event.target.name]: Number(event.target.value),
      });
    } else {
      setProfileInfo({
        ...ProfileInfo,
        [event.target.name]: event.target.value,
      });
      setEditResponse({
        ...EditResponse,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleDobChange = (e) => {
    setProfileInfo({
      ...ProfileInfo,
      dob: e._d.toString(),
    });
    setEditResponse({
      ...EditResponse,
      dob: e._d.toString(),
    });
  };

  const {
    fullName,
    email,
    dob,
    phone,
    servicesName,
    specialtiesName,
    zip,
  } = ProfileInfo ?? [];

  const dateofbirth = moment(dob).format("DD MMM YYYY");

  console.log("dateofbirth", dateofbirth);

  return (
    <>
      <div className="editProfileInfo_content_block">
        <h2>Edit your personal information</h2>
        <div className="editProfile_content">
          <div className="personal_info">
            <h5>Full name</h5>
            <CustomInput
              className="InputCustom"
              value={fullName}
              name="fullName"
              onChange={handleInputChange}
              errorText={BlurCheck["fullName"] && errors.fullName}
              onBlur={(event) => {
                setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                validate();
              }}
            />
          </div>
          <div className="personal_info">
            <h5>Mode</h5>
            <Select
              placeholder="services"
              className="MultiSelect"
              mode="multiple"
              value={servicesName && servicesName}
            >
              {servicesName?.map((item) => (
                <Option value={item}>{item}</Option>
              ))}
            </Select>
          </div>
          <div className="personal_info">
            <h5>Date Of Birth</h5>
            <div className="BirthdayInput">
              <DatePicker
                value={moment(dateofbirth, dateFormat)}
                format={dateFormat}
                onChange={handleDobChange}
                onBlur={(event) => {
                  setBlurCheck({ ...BlurCheck, dob: true });
                  validate();
                }}
              />
            </div>
          </div>
          {BlurCheck["dob"] && errors.dob && (
            <p className="ERROR">{BlurCheck["dob"] && errors.dob}</p>
          )}
          <div className="personal_info">
            <h5>Phone Number</h5>
            <CustomInput
              className="InputCustom"
              value={phone}
              name="phone"
              onChange={handleInputChange}
            />
          </div>
          <div className="personal_info">
            <h5>Email </h5>
            <CustomInput
              className="InputCustom"
              value={email}
              name="email"
            // onChange={handleInputChange}
            />
          </div>
          <div className="personal_info">
            <h5>Speciality</h5>
            <CustomInput
              className="InputCustom"
              value={specialtiesName}
              name="specialtiesName"
            // onChange={handleInputChange}
            />
          </div>
          <div className="personal_info">
            <h5>Location </h5>
            <div className="Location-Details">
              <AutoLocation
                Addressdata={ProfileInfo}
                setProfileInfo={setProfileInfo}
                setEditResponse={setEditResponse}
                EditResponse={EditResponse}
              />
            </div>
          </div>
          {errors && errors.country && (
            <p className="ERROR">{errors && errors.country}</p>
          )}

          <div className="personal_info">
            <h5>Zipcode</h5>
            <CustomInput
              className="InputCustom"
              type="number"
              value={zip}
              name="zip"
              errorText={BlurCheck["zip"] && errors.zip}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPersonalinfo;
