import React, { useState, useEffect } from "react";
import { Icon, Badge, Table,Modal } from "antd";
import moment from "moment";
import "./DashboardPayments.scss";
import { useHistory } from "react-router-dom";
import DashboardLayout from "./../DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import Axiosinstance from "../../../config";
import ViewPayments from "./ViewPayments/ViewPayments";
import GetlocalStorage from "../../../Common/setLocalStorage/GetlocalStorage";

const DashboardPayments = () => {
  const [Loader, setLoader] = useState(false);
  const [viewPaymentModal, setViewPaymentModal] = useState(false);
  const [ViewPaymentInfo, setViewPaymentInfo] = useState({});

  let getlocalStorage = GetlocalStorage();
  const history = useHistory();

  const dispatch = useDispatch();

  let CareGiverid = getlocalStorage.CareGiverid;

  const [appointmentDate, setAppointmentsData] = useState([]);

  useEffect(() => {
    async function fetchAppointments() {
      const url = `/payments/v1.0/getCGPayment/${CareGiverid}`;
      const res = await Axiosinstance(url);
      console.log("dejsbchidsbcd", res);
      if (res?.data?.data?.length > 0) setAppointmentsData(res?.data?.data);
    }
    fetchAppointments();
  }, []);
  
  const routercall = (val) => {
    // history.push(val);
  };

  const handleLoader = (val) => {
    setLoader(val);
  };

  const paymentsData = useSelector((state) => state.PaymentListReducer.data);

  const ViewPaymnetInformation = (item) => {
    console.log("paymentssData", item);
    setViewPaymentInfo(item)
    setViewPaymentModal(true)
  }

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "payment.patientName",
      key: "payment.patientName",
    },
    {
      title: "Payment Date",
      dataIndex: "payment.created_at",
      key: "payment.created_at",
      render: (val) => moment(val).format("MMM-DD-YYYY"),
    },
    {
      title: "payer Name",
      dataIndex: "payment.payerName",
      key: "payment.payerName",
      render: (record, item) => <span>{item?.payment?.paymentType ==="Online" ? item?.payment?.patientName : item?.payment?.payerName}</span>
    },
    {
      title: "Payment Type",
      dataIndex: "payment.paymentType",
      key: "payment.paymentType",
    },
    // {
    //   title: "Transaction Id",
    //   dataIndex: "payment.profileId",
    //   key: "payment.profileId",
    // },
    {
      title: "Status",
      dataIndex: "payment.paymentStatus",
      key: "payment.paymentStatus",
      render: (text, record) => (
        <span style={text === "Completed" ? { color: "#B0779A"} : { color: "#DE960C"}}>
          {text}
        </span>
      ),
    },
    {
      title: "Details",
      dataIndex: "Details",
      render: (text, record) => <Icon className="EyeIcon" type="eye" onClick={() => ViewPaymnetInformation(record, text)} />,
    },
  ];

  const data = [];

  paymentsData &&
    paymentsData.map((item, index) => {
      data.push({
        Patient_Name:(<span>{item?.paymentDetails?.paymentType === "online" ?  item?.paymentDetails?.patientName :item?.paymentDetails?.payerName}</span>),
        Date: moment(item.paymentDetails.paymentDate).format("YYYY/MM/DD"),
        Bank: item.paymentDetails.paymentType,
        Booking_Id: item.bookingId?.substring(0, 11),
        Transaction_ID: item.transactionId,
        Amount: item.paymentDetails.recievedAmount,
        Status: item.paymentDetails.paymentStatus,
      });
    });
  const fullName = getlocalStorage.fullName;

  return (
    <DashboardLayout>
      <div className="DashboardPayments-Body">
        <h3>Payments</h3>
        <div className="DashboardPayments-ProfileInfo">
          <Icon
            style={{ visibility: "hidden" }}
            type="caret-down"
            theme="filled"
          />
          <h3 onClick={() => history.push("/dashboardsettings/accountinfo")}>
            {fullName}
          </h3>
          <Badge offset={[-10, 8]} color={"#B0779A"} dot>
            <Icon
              onClick={() => history.push("/dashboardsettings/notifications")}
              type="bell"
              theme="filled"
            />
          </Badge>
        </div>
        {/* <div className="DashboardPayments-Filters">
          <Select defaultValue="Most Recently">
            <Option value="Most Recently">Most Recently</Option> 
          </Select>
          <div className="DEFAULT">
            <img src={require('../../../Assets/filter.png')} alt="icon1" />
            <p>Default</p>
          </div> 
        </div> */}
        <div className="DashboardPayments-Body-Table">
          <Table
            columns={columns}
            dataSource={appointmentDate}
            loading={Loader}
            pagination={{ pageSize: 8, size: "small" }}
          />

          <Modal
            className="view_payments"
            visible={viewPaymentModal}
            centered={true}
            footer={null}
            onCancel={() => setViewPaymentModal(false)}
          // closable={false}
          >
            <ViewPayments ViewPaymentInfo={ViewPaymentInfo} />
          </Modal>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DashboardPayments;
