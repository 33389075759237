import React, { useState, useEffect } from "react";
import "./Payouts.scss";
import { Divider, Card, Select, Button, Col, Row,Icon } from "antd";
import { Link } from "react-router-dom";
import DashboardLayout from "./../../DashboardLayout";

const { Option } = Select;

const Payouts = (props) => {
  const [Profileinput, setprofileinput] = useState();

  useEffect(() => {}, []);

  const onClickProfileinput = (val) => {
    if (Profileinput !== 0) setprofileinput(0);
    else setprofileinput(val);
  };

  const onClickSaveinput = (val) => {
    setprofileinput(0);
  };

  const onSelect = (name, e) => {};

  return (
    <DashboardLayout>
      <div className="DashboardSettings-container">
        <div className="DashboardSettings-body">
          <Link to="/dashboardsettings">
            <h3>
            <Icon type="arrow-left"/> {"Payments & Payouts"}
            </h3>
          </Link>
          <div className="Payouts-Container">
            <div className="Payouts-body-content">
              <div>
                <Row>
                  <Col lg={16} xs={24}>
                    <div className="Payouts-Body-Edit">
                      <h3>Preferred language</h3>
                      <div id="EditContent1" className="Payouts-EditContent">
                        <h3
                          className="Payouts-Edit"
                          onClick={() => onClickProfileinput(1)}
                        >
                          {Profileinput === 1 ? "Cancel" : "Edit"}
                        </h3>
                        <h3 className="Payouts-Content">English</h3>

                        {Profileinput === 1 && (
                          <div className="edit-input">
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Preferred language"
                              onChange={onSelect.bind(this, "language")}
                            >
                              <Option value="English">English</Option>
                              <Option value="Japanese">Japanese</Option>
                            </Select>
                            <Button onClick={onClickSaveinput}>saved</Button>
                          </div>
                        )}
                        <Divider className="Payouts-divider" />
                      </div>
                    </div>
                    <div className="Payouts-Body-Edit">
                      <h3>Currency</h3>
                      <h3
                        className="Payouts-Edit"
                        onClick={() => onClickProfileinput(2)}
                      >
                        {" "}
                        {Profileinput === 2 ? "Cancel" : "Edit"}
                      </h3>
                      <h3 className="Payouts-Content">United States dollar</h3>
                      {Profileinput === 2 && (
                        <div className="edit-input">
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Preferred Currency"
                            onChange={onSelect.bind(this, "currency")}
                          >
                            <Option value="Rupees">Rupees</Option>
                            <Option value="Dollar">Dollar</Option>
                          </Select>
                          <Button onClick={onClickSaveinput}>saved</Button>
                        </div>
                      )}

                      <Divider className="Payouts-divider" />
                    </div>
                    <div className="Payouts-Body-Edit">
                      <h3>Time zone</h3>
                      <h3
                        className="Payouts-Edit"
                        onClick={() => onClickProfileinput(3)}
                      >
                        {Profileinput === 3 ? "Cancel" : "Edit"}
                      </h3>
                      <h3 className="Payouts-Content">Eastern daylight time</h3>

                      {Profileinput === 3 && (
                        <div className="edit-input">
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Time zone"
                            onChange={onSelect.bind(this, "timeZone")}
                          >
                            <Option value="UST">UST</Option>
                            <Option value="IST">IST</Option>
                          </Select>
                          <Button onClick={onClickSaveinput}>saved</Button>
                        </div>
                      )}

                      <Divider className="Payouts-divider" />
                    </div>
                  </Col>
                  <Col lg={8} sm={16} xs={24}>
                    <div className="Payouts-Preferences">
                      <Card>
                        <img
                          src={require("../../../../Assets/international-icon.svg")}
                          alt="logo"
                        />
                        <p>Your global preferences</p>
                        <p className="Payouts-Preferences-Content">
                          Changing your currency updates how you See prices. You
                          can change how you get Payments in your payments &
                          payouts Preferences.
                        </p>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Payouts;
