import React from 'react';
import './ViewPayments.scss';
import moment from 'moment';

const ViewPayments = ({ ViewPaymentInfo }) => {
  const { appointmentDate, appointmentSlot, bookingId, patientContactNumber, patientAddress, patientEmail, patientName, purposeOfVist, userAge, slotSessionTime, servicesType } = ViewPaymentInfo?.appointment ?? {};
  const { recievedAmount, totalAmount, payerName, paymentStatus, paymentType, paymentDate } = ViewPaymentInfo?.payment ?? {};

  const formatDisplayPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = '+1 ';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  const getModeDisplay = (mode) => {
    switch (mode) {
      case 'CAREGIVER': return 'Care Giver';
      case 'HOMEVISIT': return 'Home Visit';
      default: return 'Care Giver';
    }
  };


  return (
    <div className='view_payment_container'>
      <div className='view_payments_content'>
        <h3>Payment Details</h3>
        <div className='payment_details'>
          <div className='details'>
            <h5>Payer name</h5>
            <span>:</span>
            <h4>{paymentType ==="Online" ? patientName : payerName}</h4>
          </div>
          <div className='details'>
            <h5>Payment Date</h5>
            <span>:</span>
            <h4>{moment(paymentDate).format('MMM-DD-YYYY')}</h4>
          </div>
          <div className='details'>
            <h5>Payment Status</h5>
            <span>:</span>
            <h4>{paymentStatus}</h4>
          </div>
          <div className='details'>
            <h5>Payment Type</h5>
            <span>:</span>
            <h4>{paymentType}</h4>
          </div>
          <div className='details'>
            <h5>Total amount</h5>
            <span>:</span>
            <h4>{totalAmount}</h4>
          </div>
          <div className='details'>
            <h5>Received amount</h5>
            <span>:</span>
            <h4>{recievedAmount}</h4>
          </div>
        </div>
      </div>
      <div className='view_payments_content'>
        <h3>Appointment Details</h3>
        <div className='payment_details'>
          <div className='details'>
            <h5>Patient Name</h5>
            <span>:</span>
            <h4>{patientName}</h4>
          </div>
          <div className='details'>
            <h5 >Patient email</h5>
            <span>:</span>
            <h4 className='email_text'>{patientEmail}</h4>
          </div>
          <div className='details'>
            <h5>Contact Number</h5>
            <span>:</span>
            <h4>{formatDisplayPhoneNumber(patientContactNumber)}</h4>
          </div>
          <div className='details'>
            <h5>Address</h5>
            <span>:</span>
            <h4>{patientAddress}</h4>
          </div>
          <div className='details'>
            <h5>Appointment Date</h5>
            <span>:</span>
            <h4>{moment(appointmentDate).format("MMM-DD-YYYY")}</h4>
          </div>
          <div className='details'>
            <h5>Appointment Slot</h5>
            <span>:</span>
            <h4>{appointmentSlot}</h4>
          </div>
          <div className='details'>
            <h5>slot Session Time</h5>
            <span>:</span>
            <h4>{slotSessionTime}</h4>
          </div>
          <div className='details'>
            <h5>Booking Id</h5>
            <span>:</span>
            <h4>{bookingId}</h4>
          </div>
          <div className='details'>
            <h5>Service Type</h5>
            <span>:</span>
            <h4>{getModeDisplay(servicesType)}</h4>
          </div>
          <div className='details'>
            <h5>Age</h5>
            <span>:</span>
            <h4>{userAge}</h4>
          </div>
        </div>
      </div>
      <div className='visit_details'>
        <h5>{"Purpose Of Visit"} </h5>
        <span>:</span>
        <h4>{purposeOfVist}</h4>
      </div>
    </div>
  );
}

export default ViewPayments;
