import React, { useState } from "react";
import { Row, Col, Modal, Divider, Table, Icon, Avatar } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import AppointmentDetails from "./../../DashboardAppointments/Components/AppointmentDetails";

const PatientInfo = ({ PatientPreview, PatientViewLoader }) => {
  const [PatientInfoDetails, setPatientInfoDetails] = useState();
  const [PatientInfoModal, setPatientInfoModal] = useState(false);
  const [PatientDetails, setPatientDetails] = useState();

  const onclick_MedicationView = (type) => {
    setPatientInfoDetails(type);
    setPatientInfoModal(true);
  };
  const onclick_DetailsView = (type, data) => {
    console.log("DATA", data.Details);
    setPatientDetails(data.Details);
    setPatientInfoDetails(type);
    setPatientInfoModal(true);
  };
  const PatientBookingData = useSelector(
    (state) => state.PatientList_bookings.data,
  );

  const formatDisplayPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = '+1 ';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  const columns = [
    { title: "Token Id", dataIndex: "Token_Id", key: "Token_Id" },
    { title: "Booking id", dataIndex: "Booking_id", key: "Booking_id" },
    { title: "Date of session", dataIndex: "Date", key: "Date" },
    { title: "Slot Timings", dataIndex: "Slot_Timings", key: "Slot_Timings" },
    
    { title: "Status", dataIndex: "Status", key: "Status" },
    {
      title: "Details",
      dataIndex: "Details",
      key: "Details",
      render: (text, record) => (
        <p
          onClick={() => onclick_DetailsView("Patient-DetailsView", record)}
          className="VIEW"
        >
          view
        </p>
      ),
    },
  ];

  console.log("PatientInfoModal", PatientDetails);
  const data = [];

  for (const i of PatientBookingData ?? []) {
    const { slotSessionTime, appointmentSlotTime } = i;
    let hours = appointmentSlotTime.slice(0, 2);
    let min = appointmentSlotTime.slice(3, 5);
    if (min == '00') min = 0;
    var startTime = moment(appointmentSlotTime, "HH:mm");
    data.push({
      key: i._id,
      Token_Id: "19J216",
      Booking_id: i.bookingId?.substring(0, 11),
      Details: i,
      Slot_Timings: `${appointmentSlotTime}- ${moment(
        startTime.add(Number(slotSessionTime), "minutes"),
      ).format("HH:mm")}`,
      Date: moment(i.appointmentDate).format("MMM-DD-YYYY"),
      Status: new Date() <= new Date(i.appointmentDate).setHours(Number(hours), Number(min), 0, 0) ? "Upcoming" : "Completed",
    });
  }

  const Modalscolumns = [
    {
      title: "Type of Medicine",
      dataIndex: "MedicineType",
      key: "MedicineType",
    },
    {
      title: "Name of medicine",
      dataIndex: "MedicineName",
      key: "MedicineName",
    },
    { title: "Dosage", dataIndex: "Dosage", key: "Dosage" },
    { title: "Duration", dataIndex: "Duration", key: "Duration" },
    { title: "Instructions", dataIndex: "Instructions", key: "Instructions" },
  ];

  const Modalsdata = [];
  for (const i of PatientDetails?.medication ?? []) {
    const { _id, type, name, dosage, duration, instructions } = i;
    Modalsdata.push({
      key: _id,
      MedicineType: type,
      MedicineName: name,
      Dosage: dosage,
      Duration: duration,
      Instructions: instructions,
    });
  }

  const { slotSessionTime, appointmentSlotTime } = PatientDetails ?? [];
  var startTime = moment(appointmentSlotTime, "HH:mm");

  return (
    <div className="PatientInfo-Container">
      <div className="PatientInfo-Body">
        <div className="PatientInfo-Body-Avatar">
          {/* <Avatar src={require("../../../../Assets/user1.svg")} size={110} />{" "} */}
          <Avatar style={{ verticalAlign: 'middle' }} size={90}>
            {PatientPreview?.patientName?.slice(0, 1)}
          </Avatar>
        </div>
        <div className="PatientInfo-Body-Details">
          {/* <h3>Name&nbsp; : &nbsp;<h4>{PatientPreview.patientName}</h4></h3> */}
          {/* <h3>Patient ID&nbsp; : &nbsp;{`PH_${PatientPreview.patientName.substring(0, 4)}`}</h3>
          <h3>Address&nbsp; : &nbsp;{PatientPreview.patientAddress}</h3> */}
          <div className="details">
            <h3>Name </h3> <span>:</span>
            <h4>{PatientPreview?.patientName}</h4>
          </div>
          <div className="details">
            <h3>Patient Id</h3> <span>:</span>
            <h4>{`PH_${PatientPreview.patientName.substring(0, 4)}`}</h4>
          </div>
          <div className="details">
            <h3>Address </h3>
            <span>:</span> <h4>{PatientPreview.patientAddress}</h4>
          </div>
        </div>
        <Divider type="vertical" />
        <div className="PatientInfo-Body-Details">
          {/* <h3>Contact&nbsp; : &nbsp;{PatientPreview.patientPhone}</h3>
          <h3>Email&nbsp; : &nbsp;{PatientPreview.patientEmail}</h3>
          <h3>Sessions&nbsp; : &nbsp;{PatientBookingData?.length}</h3> */}
          <div className="details">
            <h3>Contact </h3> <span>:</span>
            <h4>{formatDisplayPhoneNumber(PatientPreview.patientPhone)}</h4>
          </div>
          <div className="details">
            <h3>Email </h3> <span>:</span>
            <h4 className="email_text">{PatientPreview?.patientEmail}</h4>
          </div>
          <div className="details">
            <h3>Sessions </h3> <span>:</span>
            <h4>{PatientBookingData?.length}</h4>
          </div>
        </div>
      </div>
      <div className="PatientInfo-Filters">
        <h3>Sessions</h3>
        {/* <div className="DEFAULT">
          <img src={require("../../../../Assets/filter.png")} alt="icon1" />
          <p>Default</p>
        </div> */}
      </div>
      <div className="PatientInfo-Body-Table">
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 8, size: "small" }}
          loading={PatientViewLoader}
        />
      </div>

      <Modal
        className="PatientInfo_Modal"
        visible={PatientInfoModal}
        centered={true}
        footer={null}
        onCancel={() => setPatientInfoModal(false)}
        closable={false}
      >
        <>
          <Icon
            onClick={() => setPatientInfoModal(false)}
            className="IconClose"
            type="close-circle"
            theme="filled"
          />
          {PatientInfoDetails === "Patient-DetailsView" && (
            <div className="Patient-DetailsView-Body">
              <AppointmentDetails AppointmentInfo={PatientDetails} />
            </div>
          )}

          {PatientInfoDetails === "Patient-MedicationView" && (
            <div className="Patient-MedicationView-Body">
              <div className="Patient-MedicationView-Body-Table">
                <Table
                  columns={Modalscolumns}
                  dataSource={Modalsdata}
                  pagination={false}
                />
              </div>
            </div>
          )}
        </>
      </Modal>
    </div>
  );
};

export default PatientInfo;
