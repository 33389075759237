import React from "react";
import { Button, Tabs, Row, Col, Divider, Collapse,Icon } from "antd";
import "./Preferences.scss";
import { Link } from "react-router-dom";
import DashboardLayout from "./../../DashboardLayout";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const Preferences = (props) => {
  function callback(key) {
    console.log(key);
  }

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const TaxesTab = (
    <div className="TaxesTab-body">
      <Row>
        <Col lg={14} sm={24} xs={24}>
          <div className="Taxes-content-one">
            <h2>FEDERAL INCOME TAX</h2>
            <h5>
              Add a payment method using our secure payment system, then Start
              Planning your next booking.
            </h5>

            <div className="Add-Payment">
              <Button className="Add-Payment-button">ADD TAX ID NUMBER</Button>
            </div>
          </div>
        </Col>
        <Col lg={10} sm={24} xs={24}>
          <div className="Taxes-card-setup">
            <div className="Taxes-card-img">
              <img
                src={require("../../../../Assets/international-icon.svg")}
                alt="/"
              />
            </div>
            <div className="Taxes-card-info">
              <h2>Where is my TAX invoice?</h2>
              <div className="Taxes-card-sub-info">
                <h5>
                  Once you book a place for business travel,You can find a link
                  to the Federal tax invoice In the booking confirmation email.{" "}
                  <span> Learn more. </span>
                </h5>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  const PaymentTab = (
    <div className="PaymentTab-body">
      <Row>
        <Col lg={14} sm={24} xs={24}>
          <div className="Payment-content-one">
            <h2>Payment methods</h2>
            <h5>
              Add a payment method using our secure payment system, then Start
              Planning your next booking.
            </h5>

            <div className="Add-Payment">
              <Button className="Add-Payment-button">Add payment method</Button>
            </div>
          </div>

          <div className="Payment-content-Two">
            <h2>Ubora gift credit</h2>
            <h5>
              Redeem a gift card and find your credit balance. Learn more about
              gift cards. Learn more about gift cards.
            </h5>
          </div>
          <div className="Payment-content-current">
            <div className="Payment-content-current-setup">
              <h4>Current credit balance</h4>
              <h4>$0.00</h4>
            </div>
            <Divider />
            <div className="Add-Payment">
              <Button className="Add-Payment-button">Add gift card</Button>
            </div>
          </div>
        </Col>
        <Col lg={10} sm={24} xs={24}>
          <div className="Payment-card-setup">
            <div className="Payment-card-img">
              <img
                src={require("../../../../Assets/international-icon.svg")}
                alt="/"
              />
            </div>
            <div className="Payment-card-info">
              <h2>Make all payments through</h2>
              <h2> Ubora Health</h2>
              <div className="Payment-card-sub-info">
                <h5>
                  Always pay and communicate through Ubora ensure you’re
                  protected Under our <span>Terms of</span>{" "}
                  <span>Service, Payments Terms of Service,</span> Cancellation,
                  and other safeguards <span>Learn more</span>
                </h5>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  const PayoutTab = (
    <div className="PayoutTab-body">
      <Row>
        <Col lg={14} sm={24} xs={24}>
          <div className="PayoutTab-content-one">
            <h2>Payment methods</h2>
            <h5>
              Whan you receive payment for a reservation, we call that payment
              to you a ‘payout’. Our secure payment system supports several
              payout methods, which can be set up below. <span>Go to FAQ</span>
            </h5>

            <br />
            <div>
              <h5>
                To get paid, you need to set ups payment method Ubora releases
                payouts about 24 hours after a guest’s scheduled check-in-time.
                The time it takes for the funds to appear in your account
                depends on your Payout method. <span>Learn more</span>
              </h5>
            </div>

            <div className="Add-Payment">
              <Button className="Add-Payment-button">Add payment method</Button>
            </div>
          </div>
        </Col>
        <Col lg={10} sm={24} xs={24}>
          <div className="Payout-card-setup">
            <div className="Payout-card-info">
              <div className="web-faq">
                <img
                  src={require("../../../../Assets/international-icon.svg")}
                  alt="/"
                />
                <h2>FAQs</h2>
              </div>
              <div className="faq-mobile-setup">
                <div>
                  <Collapse onChange={callback} expandIconPosition="right">
                    <Panel header="How payouts work" key="1">
                      <div>{text}</div>
                    </Panel>
                    <Panel header="Adding payout info" key="2">
                      <div>{text}</div>
                    </Panel>
                    <Panel header="When will I get my payout?" key="3">
                      <div>{text}</div>
                    </Panel>
                  </Collapse>
                </div>
                <div className="Mobile-faq">
                  <img
                    src={require("../../../../Assets/international-icon.svg")}
                    alt="/"
                  />
                  <h2>FAQs</h2>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <DashboardLayout>
      <div className="DashboardSettings-container">
        <div className="DashboardSettings-body">
          <Link to="/dashboardsettings">
            <h3>
            <Icon type="arrow-left"/> {" Manage Preferences"}
            </h3>
          </Link>
          <div className="Preferences-container">
            <div className="Preferences-body">
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="PAYMENTS" key="1">
                  {PaymentTab}
                </TabPane>
                <TabPane tab="PAYOUTS" key="2">
                  {PayoutTab}
                </TabPane>
                <TabPane tab="TAXES" key="3">
                  {TaxesTab}
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Preferences;
