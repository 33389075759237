import React, { useState, useEffect } from "react";
import { List, Icon, Avatar, Rate, message, Button } from "antd";
import "./Reviews.scss";
import Axiosinstance from "../../../../config";
import GetlocalStorage from "../../../../Common/setLocalStorage/GetlocalStorage";

const Reviews = () => {
  const [ReviewList, setReviewList] = useState([]);
  const [updateReview, setupdateReview] = useState(false);
  let getlocalStorage = GetlocalStorage();
  const CareGiverid = getlocalStorage.CareGiverid;

  useEffect(() => {
    handleFetchReviews();
  }, [updateReview]);
  const handleFetchReviews = async () => {
    try {
      const res = await Axiosinstance.get(
        `client/doc/v1.0/getReviews/vewAll/${CareGiverid}`
      );
      if (res.status === 200) {
        setReviewList(res.data.data);
      }
    } catch (error) {}
  };
  const handleupdateReviews = async ({ _id }) => {
    const data = {
      isApproved: true,
    };

    try {
      const res = await Axiosinstance.put(
        `/client/doc/v1.0/updateReview/${_id}`,
        data
      );
      if (res.status === 200) {
        setupdateReview((prestate) => !prestate);
        message.success("Review Updated successfully");
      }
    } catch (error) {}
  };

  var totalrating = ReviewList.reduce(function (acc, item) {
    return acc + item.rating;
  }, 0);
  const avgrating = Math.abs(totalrating / ReviewList.length);
  return (
    <div className="Reviews-Container">
      <h1>
        Patients Overall Reviews :
        <Avatar
          style={{
            backgroundColor: "#81837f",
            verticalAlign: "middle",
            marginLeft: 8,
          }}
          size="large"
        >
          {avgrating ? avgrating.toFixed(2) : 0}
        </Avatar>
      </h1>
      <div className="Reviews-Container-body">
        <List
          dataSource={ReviewList}
          renderItem={(item) => (
            <List.Item key={item._id}>
              <List.Item.Meta
                avatar={
                  item.patientProfileImg ? (
                    <Avatar src={item.patientProfileImg} />
                  ) : (
                    <Avatar className="NoProfileImg">
                      {item.patientName &&
                        item.patientName
                          ?.replace(/[^a-zA-Z0-9]/g, "")
                          .charAt(0)}
                    </Avatar>
                  )
                }
                title={<span>{item.patientName}</span>}
                description={item.review}
              />

              <div style={{ textAlign: "center" }}>
                {!item.isApproved ? (
                  <Icon
                    type="eye-invisible"
                    onClick={() => handleupdateReviews(item)}
                  />
                ) : (
                  <>
                    <Icon type="eye" theme="filled" />
                  </>
                )}
                {item.isRejected && (
                  <Button
                    type="danger"
                    style={{
                      position: "relative",
                      bottom: "1rem",
                      left: "1rem",
                    }}
                  >
                    Rejected
                  </Button>
                )}
                <br />
                <Rate disabled defaultValue={item.rating} />
              </div>
            </List.Item>
          )}
        ></List>
      </div>
    </div>
  );
};

export default Reviews;
