let baseUrl="https://uatcareapi.uborahealth.info/api/"
// let baseUrl ="http://localhost:8080/api";
// const { hostname } = window.location;
// if (hostname === "www.app.caregiver.uborahealth.info") {
//   //baseUrl = "https://caregiver.uborahealth.info/api";
//   baseUrl = "https://uatcareapi.uborahealth.info/api";
// } else if (hostname === "dev.d3q1ext8z3b7al.amplifyapp.com") {
//   // baseUrl = 'https://ubdev.caregiver.dev2prod.com/api'
//   baseUrl = 'https://dev-care-giver-api-ub.up.railway.app/api';
// } else if (hostname === "localhost") {
//   // baseUrl = "https://caregiver.uborahealth.info/api";
//   baseUrl = 'https://dev-care-giver-api-ub.up.railway.app/api';
// } else {
//   //baseUrl = 'https://ubdev.caregiver.dev2prod.com/api'
//   baseUrl = 'https://dev-care-giver-api-ub.up.railway.app/api';
// }

export default baseUrl;