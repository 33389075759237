
import {  Doctor_Signup_Request } from "./SignupActionTypes";
import  Axiosinstance from "../../config";
import { message } from 'antd';
import { removeKeyFromLocalStorage } from "../../Common/setLocalStorage/clearLocalStorage";



const create = (body) =>  Axiosinstance.post("auth/v1.0/signup", body);
export const Doctor_Signup_Action = (data,routercall,handleLoader) => async (dispatch) => {
 
    try {
      handleLoader(true)
        const res = await create(data);
        console.log("res", res);
        if (res.status === 200) {
          dispatch(Doctor_Signup(res.data)) 
          removeKeyFromLocalStorage("servicesName")
          removeKeyFromLocalStorage("servicesID")
          routercall("/confirmregister")
          handleLoader(false)
          message.success(res.data.message)
        }
        else if (res.status === 404){
          console.log("resstatus",res.data.message)
          routercall(res.data.message)
          handleLoader(false);
        }
        else if (res.status === 400){
          console.log("resstatus",res.data.message)
          routercall("plese Fill Details")
          handleLoader(false);
        }
      } catch (error) {
        console.log("Provider_Signup_req", error);
      }
};

const Doctor_Signup = (Providerinfo) => ({
  type: Doctor_Signup_Request,
  payload: Providerinfo,
});
