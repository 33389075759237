import React, { useState } from "react";
import "./Security.scss";
import { Link } from "react-router-dom";
import { Divider, Card, Tabs, Col, Row, Input, Button, Modal, Icon } from "antd";
import DashboardLayout from "./../../DashboardLayout";

const { TabPane } = Tabs;

const initialState = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const Security = (props) => {
  const [passwordChange, setpasswordChange] = useState(initialState);
  const [modalVisible, setmodalVisible] = useState(false);
  const [errors, setErrors] = useState({});
  let [BlurCheck, setBlurCheck] = useState({
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const { oldPassword, newPassword, confirmNewPassword } = passwordChange;

  const inputChange = (e) => {
    const { name, value } = e.target;
    setpasswordChange({ ...passwordChange, [name]: value });
  };

  let validate = () => {
    let errors = {};
    //old password can't be blank
    if (!oldPassword) {
      errors.oldPassword = "Old Password can't be blank";
    }
    //password regex
    const validPasswordRegex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15})/;
    if (oldPassword) {
      if (!validPasswordRegex.test(oldPassword)) {
        errors.oldPassword =
          "Password should be 6 to 15 characters long with at least one uppercase letter, one lowercase letter and one digit";
      }
    }
    //new password can't be blank
    if (!newPassword) {
      errors.newPassword = "New Password can't be blank";
    }
    if (newPassword) {
      if (!validPasswordRegex.test(newPassword)) {
        errors.newPassword =
          "Password should be 6 to 15 characters long with at least one uppercase letter, one lowercase letter and one digit";
      }
    }
    if (!confirmNewPassword) {
      errors.confirmNewPassword = "Confirm New Password can't be blank";
    } else if (newPassword !== confirmNewPassword) {
      errors.confirmNewPassword =
        "New Password and Confirm New Password should be same";
    }
    setErrors(errors);
  };

  let isValid = () => {
    let valid = true;
    //reading all controls from 'errors' state
    for (let control in errors) {
      if (errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  const onUpdate = () => {
    let BlurCheckData = BlurCheck;
    Object.keys(BlurCheck).forEach((control) => {
      BlurCheckData[control] = true;
    });
    setBlurCheck(BlurCheckData);
    validate();
    if (isValid()) {
      let data = {
        oldPassword,
        newPassword,
        confirmNewPassword,
      };
      setmodalVisible(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="DashboardSettings-container">
        <div className="DashboardSettings-body">
          <Link to="/dashboardsettings">
            <h3 >
              <Icon type="arrow-left"/> {"Login & Security"}
            </h3>
          </Link>
          <div className="Security-Container">
            <div className="Security-Body-content">
              <Row>
                <Col lg={16} sm={24} xs={24}>
                  <div className="Security-Body-Edit">
                    <Tabs>
                      <TabPane tab="LOGIN" key="1">
                        <div>
                          <h2>Login</h2>
                          <h3>Password</h3>
                          <h3
                            className="Security-Edit"
                            style={{ cursor: "pointer" }}
                            onClick={() => setmodalVisible(true)}
                          >
                            Update
                          </h3>
                          <h3 className="Security-Content">
                            Last updated a month ago
                          </h3>
                          <Divider className="DashboardSecurity-divider" />
                        </div>
                        <div>
                          <h2>Social accounts</h2>
                          <h3>Facebook</h3>
                          <h3 className="Security-Edit">Connect</h3>
                          <h3 className="Security-Content">Not connected</h3>
                          <Divider className="Security-divider" />
                          <h3>Google</h3>
                          <h3 className="Security-Edit">Disconnect</h3>
                          <h3 className="Security-Content">Connected</h3>
                          <Divider className="Security-divider" />
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                </Col>
                <Col lg={8} sm={18} xs={24}>
                  <div className="Security-Body-Safe">
                    <Card>
                      <img
                        src={require("../../../../Assets/international-icon.svg")}
                        alt="logo"
                      />
                      <p>Make your profile safe and Secure</p>
                      <p className="Security-Safe-Content">
                        Always pay and communicate through Ubora to ensure
                        you’re protected Under our
                        <span style={{ color: "#2D80B5" }}>
                          Terms of Service, Payments Terms of Service
                        </span>
                        , Cancellation, and other safeguards.
                        <span style={{ color: "#2D80B5" }}>Learn more</span>
                      </p>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <Modal
          className="PasswordModal"
          centered
          footer={null}
          visible={modalVisible}
          onCancel={() => setmodalVisible(false)}
        >
          <h2>Update your Password</h2>
          <Input.Password
            placeholder="Old Password"
            value={oldPassword}
            name="oldPassword"
            onChange={inputChange}
            onBlur={(event) => {
              setBlurCheck({
                ...BlurCheck,
                [event.target.name]: true,
              });
              validate();
            }}
          />
          {BlurCheck["oldPassword"] && errors.oldPassword && (
            <p className="Error">
              {BlurCheck["oldPassword"] && errors.oldPassword}
            </p>
          )}
          <Input.Password
            placeholder="New Password"
            value={newPassword}
            name="newPassword"
            onChange={inputChange}
            onBlur={(event) => {
              setBlurCheck({
                ...BlurCheck,
                [event.target.name]: true,
              });
              validate();
            }}
          />
          {BlurCheck["newPassword"] && errors.newPassword && (
            <p className="Error">
              {BlurCheck["newPassword"] && errors.newPassword}
            </p>
          )}
          <Input.Password
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            name="confirmNewPassword"
            onChange={inputChange}
            onBlur={(event) => {
              setBlurCheck({
                ...BlurCheck,
                [event.target.name]: true,
              });
              validate();
            }}
          />
          {BlurCheck["confirmNewPassword"] && errors.confirmNewPassword && (
            <p className="Error">
              {BlurCheck["confirmNewPassword"] && errors.confirmNewPassword}
            </p>
          )}
          <div className="Btn-Setup">
            <Button className="UpdateBtn" type="primary" onClick={onUpdate}>
              Update
            </Button>
            <Button
              className="CancelBtn"
              onClick={() => setmodalVisible(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
    </DashboardLayout>
  );
};

export default Security;
